/* eslint-disable array-callback-return */
import "./consulta-vin.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import DamageHistory from "@pages/queries/consulta-vin/components/DamageHistory";
import DigitalizedDocuments from "@pages/queries/consulta-vin/components/DigitalizedDocuments";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import LocationHistory from "@pages/queries/consulta-vin/components/LocationHistory";
import Messages from "@pages/queries/consulta-vin/components/Messages";
import MoveHistory from "@pages/queries/consulta-vin/components/MoveHistory";
import OcurrenceHistory from "@pages/queries/consulta-vin/components/OccurrenceHistory";
import Operations from "@pages/queries/consulta-vin/components/Operations";
import Third from "@pages/queries/consulta-vin/components/Third";
import VinView from "@pages/queries/consulta-vin/components/VinView";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useFilterContext } from "../../../protected-routes";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

export function ConsultaVin() {
  const SCREEN_CODE = "con0020_vin_danios";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);

  const [vinData, setVinData] = useState(null);
  const [vinObject, setVinObject] = useState({});
  const [listVinObject, setListVinObject] = useState([]);

  const [selectedCliente, setSelectedCliente] = useState("");
  const [codVin, setCodVin] = useState("");
  const [listClientes, setListClientes] = useState([]);
  const [modal, setModal] = useState(false);
  const [loadingVin, setLoadingVin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clienteObject, setClienteObject] = useState({});
  
  const [activeTabIndex] = useState(0);
  const filterLabelId = [1491, 54];
  const filterNames = [];
  const filterColumnsId = [
    4245, 5715, 4891, 5720, 1491, 2892, 10774, 2496, 396,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    15739, 1480, 1, 29, 31, 32, 42, 54, 65, 254, 256, 266, 275, 294, 319, 326,
    367, 382, 396, 449, 466, 492, 653, 654, 655, 753, 901, 921, 927, 990, 991,
    992, 1081, 1136, 1137, 1142, 1150, 1152, 1153, 1165, 1166, 1184, 1197, 1491,
    1505, 1669, 1791, 2131, 2139, 2140, 2205, 2490, 2496, 2502, 2717, 2760,
    2892, 3344, 3505, 3625, 3701, 3703, 3705, 3709, 3741, 4006, 4061, 4102,
    4492, 4724, 5003, 5044, 5271, 5354, 5583, 5628, 5788, 6261, 7811, 9564,
    9574, 9578, 9620, 10034, 10540, 11097, 11314, 11370, 12103, 13324, 13335,
    13338, 13706, 13798, 13928, 14288, 15288, 15538, 16416, 16440, 16448, 16456,
    16541, 16544, 16553, 16554, 16555, 16556, 16557, 16558, 16559, 16571, 16572,
    16575, 16576, 16577, 16578, 16579, 16580, 448, 1401, 1161, 2762, 3702, 254,
    9573, 1399, 18096, 18097, 18098, 18099, 18125, 18202, 15596, 18604, 3344,
    6197, 20854, 11443, 21675, 7280, 21836, 9853, 5854, 18154, 11849, 17926, 31,
    8385, 1342, 6781, 13358, 3184, 1316, 10794, 753, 22209, 1669, 22700, 9955,
    16563, 54, 6256, 22796, 22908, 22909, 2940, 5044, 16563, 11849, 8385, 23220,
    22102, 3244,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  usePageViews();

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider
      id={id}
      alt={alt}
    />,
      life: 3000,
    });
  };


  const handleKeyDown = (event) => {
    if (event.key !== "Enter") return;
    setLoadingVin(true);
      if(codVin.length >=8 && codVin.length <=17) {
      findFirstBy();
    }else {
      setLoadingVin(false);
      return showError("653", "Debe ingresar un minimo de 8 y un maximo de 17 dígitos")
    }
  };

  async function findFirstBy() {
      try {
      const { status, data } = await sipcoAxiosService.get(
        "report/findFirstBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.value,
            codCuenta: securityFilters.securityValues?.account?.value,
            codVin: codVin
          },
        }
      );
      if (status === 200) {
        if(data.length === 0) return showError("1480", "El VIN no existe" );
        if (codVin.length === 8) { //pesquisa pelo vis
          
          setVinObject({});
          setListVinObject(data);
          setModal(true);
        } else if (data.length >= 1) {
          setVinObject(data[0]);
          setLoadingVin(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findClienteByVin() {
    setSelectedCliente("");

    if (codVin.length === 8) {
     return showError("681", "Seleccione un vin");
    }
  
    try {
      const { status, data } = await sipcoAxiosService.get(
        "report/findClientesVinBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.value,
            codCuenta: securityFilters.securityValues?.account?.value,
            codVin: codVin,
          },
        }
      );
      if (status === 200) {
        if (data.length === 0) {
          setSelectedCliente(null);
          return showError("1480",
          "El VIN no existe"
          );
        }
        
        setListClientes(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function findFullByPk(vin) {
    setCodVin(vin?.id);
    setLoadingVin(false);
  }

  function selectionVin() {
    if((vinObject === null || Object.keys(vinObject).length === 0)){
      return showError("681", "Seleccione un vin");
    }
    setModal(false);
    findFullByPk(vinObject);
  }

  function findDatas (){
    loadVinVinReportData(clienteObject);
  }
  
  async function loadVinVinReportData(cliente) {
    if (!selectedCliente) {
      return;
    }

    try {
      const reportResult = await sipcoAxiosService.get("/report/vinReport", {
        params: {
          codPais: securityFilters.securityValues?.country?.value,
          codCuenta: securityFilters.securityValues?.account?.value,
          codCliente: cliente.id,
          codVin: codVin
        }
      })

      if(reportResult){
        const { status, data }= reportResult;
        if(status === 200 && data){
          setVinData(data);
        } 
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="consulta-vin">
      <Toast ref={toast} position="top-center" />
      <div className="card flex justify-content-center">
        <Dialog
          visible={modal}
          modal
          header={
            <span className="font-bold white-space-nowrap">
              <LanguageProvider id={681} alt="Seleccione un vin" />
            </span>
          }
          footer={
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={selectionVin}
              autoFocus
            />
          }
          style={{ width: "50rem" }}
          onHide={() => {
            if (!modal) return;
            setModal(false);
          }}
        >
          <div className="m-0" style={{ padding: "1rem" }}>
            <ListBox
              value={vinObject}
              onChange={(e) => {
                setVinObject(e.value);
              }}
              options={listVinObject}
              optionLabel="id"
              style={{ width: "100%" }}
            />
          </div>
        </Dialog>
      </div>

      <Filter
        onSearch={findDatas}
        securityOptions={{
          account: true,
          client: false,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div style={{position: "relative"}} className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[0].id} alt="Vin" />
              </label>

              <IconField style={{position: "absolute", bottom: "35%", right: "5px",  }}>
                <InputIcon disabled={loadingVin} className="pi pi-spin pi-spinner" style={{zIndex: `${loadingVin? 1 : 0}`,}}> </InputIcon>
              </IconField>

              <AutoComplete
                value={codVin}
                suggestions={listVinObject}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setSelectedCliente("");
                  setCodVin(e.value.trim());
                }}
                field="id"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[1].id} alt="Cliente" />
              </label>
              <AutoComplete
                value={selectedCliente}
                suggestions={listClientes}
                completeMethod={findClienteByVin}
                onChange={(e) => {
                  setSelectedCliente(e?.value?.descripcion);
                  setClienteObject(e.value);
                  loadVinVinReportData(e?.value);
                }}
                field="descripcion"
                dropdown={true}
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Accordion multiple activeIndex={[0, 1]}>
        <AccordionTab header="Dados VIN">
          <TabView activeIndex={activeTabIndex}>
            <TabPanel header="VIN">
              <VinView vinData={vinData} />
            </TabPanel>
            <TabPanel header="Histórico de Movimento">
              <MoveHistory vinData={vinData} />
            </TabPanel>
            <TabPanel header="Histórico de Danos">
              <DamageHistory vinData={vinData} />
            </TabPanel>
            <TabPanel header="Mensagens">
              <Messages vinData={vinData} />
            </TabPanel>
            <TabPanel header="Operações">
              <Operations vinData={vinData} />
            </TabPanel>
            <TabPanel header="Histórico de Localizações">
              <LocationHistory vinData={vinData} />
            </TabPanel>
            <TabPanel header="Documentos Digitalizados">
              <DigitalizedDocuments vinData={vinData} />
            </TabPanel>
            <TabPanel header="Histórico de Oocorrências">
              <OcurrenceHistory vinData={vinData} />
            </TabPanel>
            {/*// DO TO - Flavio falou que não utilizam essa aba*/}
            <TabPanel header="Terceiros">
              <Third vinData={vinData} />
            </TabPanel>
          </TabView>
        </AccordionTab>
      </Accordion>
    </div>
  );
}

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import React from "react";
import {LanguageProvider} from "@shared/components/language-provider";
const Third: React.FC<{ vinData: any }> = ({ vinData }) => {
//     const dateBodyTemplate = (rowData, field) => {
//     const date = new Date(rowData[field].date);
//     const formattedDate = date?.toLocaleDateString('pt-BR');
//     const formattedTime = date?.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
//     return (
//         <>{`${formattedDate} ${formattedTime}`}</>
//     );
// }
    const filterLabelId =[]
    const filterNames = [];
    const filterColumnsId = [16563,54,6256,22796,22908,22909,2940,1342,23220,5044,16563,11849,8385]
    const columnsNames = [];
    for (let i = 0; i < filterLabelId.length; i++) {
        filterNames.push({
            id: filterLabelId[i],
            label: LanguageProvider({ id: filterLabelId[i], alt: "Error Filter Labels" })
        });
    }
    for (let i = 0; i < filterColumnsId.length; i++) {
        columnsNames.push({
            id: filterColumnsId[i],
            label: LanguageProvider({ id: filterColumnsId[i] , alt: "Error Columns Labels"})
        });
    }

    return (
        <div className="Third">
            <Panel >
                <DataTable size={"small"} showGridlines stripedRows  value={vinData?.reporteCaratula.filas} paginator scrollHeight="flex" filterDisplay="row" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}" style={{maxWidth:"100%"}} >
                    <Column field="0" sortable filter header="Número Oferta Servicio"></Column>
                    <Column field="1" sortable filter header="Cliente"></Column>
                    <Column field="2" sortable filter header="Fecha Cortizacion"></Column>
                    <Column field="3" sortable filter header="Referencia Cliente"></Column>
                    <Column field="4" sortable filter header="Punto Origen"></Column>
                    <Column field="5" sortable filter header="Punto Destino"></Column>
                    <Column field="6" sortable filter header="Número Hoja de Ruta"></Column>
                    <Column field="7.date" sortable filter header="Fecha Despacho"></Column>
                    <Column field="8" sortable filter header="Usuario Despacho"></Column>
                </DataTable>
                <DataTable size={"small"} showGridlines stripedRows  paginator scrollHeight="flex" filterDisplay="row" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}" style={{width:"100%"}} >
                    <Column field="fechaInr" sortable filter header="País"></Column>
                    <Column field="playas" sortable filter header="Número Oferta Servicio"></Column>
                    <Column field="ubicaciones" sortable filter header="Nombre Archivo"></Column>
                    <Column field="Transaccciones" sortable filter header="Fecha Carga"></Column>
                </DataTable>
            </Panel>
        </div>
    );
};
export default Third
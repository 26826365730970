import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function IngressoPlaya() {
  const SCREEN_CODE = "con0022_ingreso_playa";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    55555, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
  ];
  const filterNames = [];
  const filterColumnsId = [
    1396, 1491, 449, 466, 10774, 2496, 396, 408, 491, 4169, 18202, 376, 4197,
    9896, 9895, 4102, 12103, 2978, 4724, 22102,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1182, 4102, 10531, 65, 5583, 5044, 54, 1791, 467, 468, 449, 10774, 396, 408,
    491, 748, 749, 466, 2496, 1396, 1491, 4169, 376, 4197, 9896, 9895, 12103,
    2978, 18202, 10021, 10034, 4724, 22102,
  ];

  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }

  const [loading, setLoading] = useState(false);
  const [playas, setPlayas] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [colores, setColores] = useState([]);
  const [familias, setFamilias] = useState([]);

  const [filter, setFilter] = useState({
    fecha: [],
    gfc06: false,
    gfc83: false,
    gfc40: false,
    gfc50: false,
    gfc76: false,
    gfc80: false,
    playas: [],
    mercados: [],
    marcas: [],
    modelos: [],
    colores: [],
    familias: [],
  });

  async function searchMercados(event: any) {
    await loadMercados(event.query);
  }
  async function loadMercados(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "ingresso-playa/mercados",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setMercados(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setMercados(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchMarcas(event: any) {
    await loadMarcas(event.query);
  }
  async function loadMarcas(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "ingresso-playa/marca",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setMarcas(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setMarcas(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchModelos(event: any) {
    await loadModelos(event.query);
  }
  async function loadModelos(query = "") {
    try {
      if (filter.marcas.length === 1) {
        const selectedBrand = filter.marcas[0];
        const { status, data } = await sipcoAxiosService.get(
          "ingresso-playa/modelos",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setModelos(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setModelos(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchColores(event: any) {
    await loadColores(event.query);
  }
  async function loadColores(query = "") {
    try {
      if (filter.marcas.length === 1) {
        const selectedBrand = filter.marcas[0];
        const { status, data } = await sipcoAxiosService.get(
          "ingresso-playa/colores",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setColores(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setColores(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchFamilias(event: any) {
    await loadFamilias(event.query);
  }
  async function loadFamilias(query = "") {
    try {
      if (filter.marcas.length === 1) {
        const selectedBrand = filter.marcas[0];
        const { status, data } = await sipcoAxiosService.get(
          "ingresso-playa/familias",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setFamilias(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setFamilias(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchPlayas(event: any) {
    await loadPlayas(event.query);
  }
  async function loadPlayas(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "subcuentas-options",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCuenta: securityFilters.securityValues.account.value,
            descripcion: query,
          },
        }
      );
      if (status === 200) {
        const playas = data.map((x: any) => {
          return { ...x, label: `${x.descripcion} - (${x.codSubcuenta})` };
        });
        if (query) {
          setPlayas(
            playas.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setPlayas(playas);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    setLoading(true);
    try {
      let transacciones = [];
      if (filter.gfc06) {
        transacciones.push("GFC06");
      }
      if (filter.gfc40) {
        transacciones.push("GFC40");
      }
      if (filter.gfc50) {
        transacciones.push("GFC50");
      }
      if (filter.gfc76) {
        transacciones.push("GFC76");
      }
      if (filter.gfc80) {
        transacciones.push("GFC80");
      }
      if (filter.gfc83) {
        transacciones.push("GFC83");
      }
      const { status, data } = await sipcoAxiosService.post(
        "ingresso-playa/report",
        {
          codPais: securityFilters?.securityValues?.country?.value || null,
          codCliente: securityFilters?.securityValues?.client?.value || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.value || null,
          transacciones: transacciones,
          playas: filter?.playas?.map((x) => x.codSubcuenta) || [],
          mercado: filter?.mercados?.map((x) => x.id) || [],
          marcas: filter?.marcas?.map((x) => x.id) || [],
          modelos: filter?.modelos?.map((x) => x.id) || [],
          colores: filter?.colores?.map((x) => x.id) || [],
          familias: filter?.familias?.map((x) => x.id) || [],
          rangeFecha: filter?.fecha || [],
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.gfc06}
                onChange={(e) => {
                  setFilter({ ...filter, gfc06: e.checked });
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[0].label}
                  alt="GFC06-Recepcíon de Transferencia"
                />
              </label>
            </div>
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.gfc83}
                onChange={(e) => {
                  setFilter({ ...filter, gfc83: e.checked });
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[0].label}
                  alt="GFC83-Devolucion o Reingreso a Playa"
                />
              </label>
            </div>
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.gfc40}
                onChange={(e) => {
                  setFilter({ ...filter, gfc40: e.checked });
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[1].label}
                  alt="GFC40-Ingreso a Stock Fabricacíon Nacional"
                />
              </label>
            </div>
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.gfc50}
                onChange={(e) => {
                  setFilter({ ...filter, gfc50: e.checked });
                }}
                style={{ justifyContent: "center", position: "relative" }}
                disabled={filter.gfc76 || filter.gfc80}
              />
              <label>
                <LanguageProvider
                  id={filterNames[2].id}
                  alt="GFC50-Disponibilida Vehículos Importados"
                />
              </label>
            </div>
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.gfc76}
                onChange={(e) => {
                  setFilter({ ...filter, gfc76: e.checked });
                }}
                style={{ justifyContent: "center", position: "relative" }}
                disabled={filter.gfc50}
              />
              <label>
                <LanguageProvider
                  id={filterNames[3].id}
                  alt="GFC76-Bajada de Buque"
                />
              </label>
            </div>
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.gfc80}
                onChange={(e) => {
                  setFilter({ ...filter, gfc80: e.checked });
                }}
                style={{ justifyContent: "center", position: "relative" }}
                disabled={filter.gfc50}
              />
              <label>
                <LanguageProvider
                  id={filterNames[4].id}
                  alt="GFC80-Ingreso a Playa Fiscal (Impo Terrestre)"
                />
              </label>
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[5].id} alt="Playas" />
              </label>
              <AutoComplete
                value={filter.playas}
                suggestions={playas}
                completeMethod={searchPlayas}
                onChange={(e) => setFilter({ ...filter, playas: e.value })}
                field="label"
                dropdown
                multiple={true}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[6].id} alt="Mercados" />
              </label>
              <AutoComplete
                value={filter.mercados}
                suggestions={mercados}
                completeMethod={searchMercados}
                onChange={(e) => setFilter({ ...filter, mercados: e.value })}
                field="label"
                dropdown
                multiple={true}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[7].id} alt="Marcas" />
              </label>
              <AutoComplete
                value={filter.marcas}
                suggestions={marcas}
                completeMethod={searchMarcas}
                onChange={(e) => setFilter({ ...filter, marcas: e.value })}
                field="label"
                dropdown
                multiple={true}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[8].id} alt="Modelos" />
              </label>
              <AutoComplete
                value={filter.modelos}
                suggestions={modelos}
                completeMethod={searchModelos}
                onChange={(e) => setFilter({ ...filter, modelos: e.value })}
                field="label"
                dropdown
                multiple={true}
                disabled={filter.marcas.length !== 1}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[9].id} alt="Colores" />
              </label>
              <AutoComplete
                value={filter.colores}
                suggestions={colores}
                completeMethod={searchColores}
                onChange={(e) => setFilter({ ...filter, colores: e.value })}
                field="label"
                dropdown
                multiple={true}
                disabled={filter.marcas.length !== 1}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[10].id} alt="Familias" />
              </label>
              <AutoComplete
                value={filter.familias}
                suggestions={familias}
                completeMethod={searchFamilias}
                onChange={(e) => setFilter({ ...filter, familias: e.value })}
                field="label"
                dropdown
                multiple={true}
                disabled={filter.marcas.length !== 1}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[11].id} alt="Fecha" />
              </label>
              <Calendar
                value={filter.fecha}
                selectionMode="range"
                onChange={(e) => {
                  setFilter({ ...filter, fecha: e.value });
                }}
                dateFormat="dd/mm/yy"
                hideOnRangeSelection
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Ingresso Playa</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"IngresoPlaya"}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"IngresoPlaya"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default IngressoPlaya;

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import React from "react";
import {LanguageProvider} from "@shared/components/language-provider";
import {convertDateObjects} from "@shared/components/utils/convertDateObjects";
const Operations: React.FC<{ vinData: any }> = ({ vinData }) => {
    const data =  convertDateObjects(vinData?.reporteOperaciones.filas);
    const filterLabelId =[]
    const filterNames = [];
    const filterColumnsId = [54,13324,16580,31,3344,5788,16416,32,31,13706,5271,1669,15739]
    const columnsNames = [];
    for (let i = 0; i < filterLabelId.length; i++) {
        filterNames.push({
            id: filterLabelId[i],
            label: LanguageProvider({ id: filterLabelId[i], alt: "Error Filter Labels" })
        });
    }
    for (let i = 0; i < filterColumnsId.length; i++) {
        columnsNames.push({
            id: filterColumnsId[i],
            label: LanguageProvider({ id: filterColumnsId[i] , alt: "Error Columns Labels"})
        });
    }

    return (
        <div className="Operations">
            <Panel >
                <DataTable size={"small"} showGridlines stripedRows  value={data} scrollHeight="flex" filterDisplay="row" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}" style={{maxWidth:"100%"}} >
                    <Column field="0" sortable filter header="Cliente"></Column>
                    <Column field="1" sortable filter header="Número Operacion"></Column>
                    <Column field="2" sortable filter header="Fecha Realizada"></Column>
                    <Column field="3" sortable filter header="Descripcion"></Column>
                    <Column field="4" sortable filter header="Estado"></Column>
                    <Column field="5" sortable filter header="Línea"></Column>
                    <Column field="6" sortable filter header="Descripcion Línea"></Column>
                    <Column field="7" sortable filter header="Valor"></Column>
                    <Column field="8" sortable filter header="Descripcion"></Column>
                    <Column field="9" sortable filter header="Usuario Ultima Modificiacion"></Column>
                    <Column field="10" sortable filter header="Fecha Ultima Modificacion"></Column>
                    <Column field="11" sortable filter header="Observacion"></Column>
                    <Column field="12" sortable filter header="Fecha Alta"></Column>
                </DataTable>
            </Panel>
        </div>
    );
};
export default Operations
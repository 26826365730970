import "./scss/filter.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import { FilterData, useFilterContext } from "../../../protected-routes";
import React, { ReactNode, useCallback, useContext, useState } from "react";
import useAuth, { AuthContext } from "@shared/AuthContext";

import { Button } from "primereact/button";
import { CustomFilter } from "./components/CustomFilter";
import { FitlerSecuritySection } from "./components/FitlerSecuritySection";
import { LanguageProvider } from "../language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export interface FilterSecurityOptions {
  country?: boolean;
  client?: boolean;
  account?: boolean;
  subaccount?: boolean;
  allClientsOptions?: boolean;
  screenCode?: string;
}
export interface customFilter {
  variable?: string;
  label?: string;
  type:
    | "dropdown"
    | "textVin"
    | "text"
    | "date"
    | "multipleSelection"
    | "checkbox"
    | "radioButton"
    | "checkboxDate"
    | "textRange"
    | "radiobuttonDate"
    | "dropdownRange";
  options?: string[];
  urlApi?: string;
  parametros?: string[];
  descripcionAddCode?: boolean;
  optionAll?: boolean;
}
export interface FilterOptions {
  customFilters?: customFilter[];
  urlApi?: string;
  parametros?: string[];
}
export interface FilterProps {
  onSearch: (e: FilterData) => void | null;
  filterOptions?: FilterOptions;
  securityOptions?: FilterSecurityOptions;
  children?: ReactNode;
}
export interface FilterValues {
  customFilters: {
    [key: string]: any;
  };
}

export function Filter(props: FilterProps): React.ReactElement {
  const { children } = props;
  const { user } = useAuth();
  const { values, setValues } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();

  const emptyFilterValues: FilterData = {
    securityValues: {
      country: user.pais || "",
      client: user.cliente || "",
      account: user.cuenta || "",
      subaccount: user.subcuenta || "",
    },
    filterValues: {
      customFilters: {},
    },
  };

  // Inicializando os valores dos checkboxes como false
  if (props.filterOptions && props.filterOptions.customFilters) {
    props.filterOptions.customFilters.forEach((customFilter) => {
      if (
        customFilter.type === "checkbox" ||
        customFilter.type === "checkboxDate"
      ) {
        emptyFilterValues.filterValues.customFilters[customFilter.label] =
          false;
      }
    });
  }

  const searchSuggestions = useCallback(
    async (event) => {
      let params = new URLSearchParams();
      let reporteDto = {}; // Definindo reporteDto aqui

      if (props.filterOptions.parametros) {
        props.filterOptions.parametros.forEach((parametro) => {
          switch (parametro) {
            case "codPais":
              params.append(
                "codPais",
                values.securityValues.country.value.toString()
              );
              break;
            case "codCliente":
              if (
                values &&
                values.filterValues.customFilters &&
                "Cliente" in values.filterValues.customFilters
              ) {
                params.append(
                  "codCliente",
                  values.filterValues.customFilters.Cliente.id
                );
              }
              break;
            case "codCuenta":
              params.append(
                "codCuenta",
                values.securityValues.account.value.toString()
              );
              break;
            case "codSubcuenta":
              params.append(
                "codSubcuenta",
                values.securityValues.subaccount.value.toString()
              );
              break;
            case "descripcion":
              params.append("descripcion", event.query);
              break;
            case "VIN":
              if (
                values &&
                values.filterValues.customFilters &&
                "VIN" in values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  // Modificando reporteDto aqui
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  cliente: values.filterValues.customFilters["Cliente"]
                    ? values.filterValues.customFilters["Cliente"].id
                    : undefined,
                  vin: values.filterValues.customFilters.VIN,
                };
              }
              break;

            case "StockTaller":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  // Modificando reporteDto aqui
                  codPais: values.securityValues.country.value,
                  codCliente: values.securityValues.client.value,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codTaller: values.filterValues.customFilters.Taller.codTaller,
                  codRubro: values.filterValues.customFilters.Rubro.codRubro,
                };
              }
              break;
            case "Stock":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  codCuenta: values.securityValues.account.value,
                  stock: values.filterValues.customFilters["Stock"],
                  mostrarRazonSocialUltimaNF:
                    values.filterValues.customFilters[
                      "Mostrar razón Social Ultima NF"
                    ],
                  estadoRemito: Array.isArray(
                    values.filterValues.customFilters["Estado Remito"]
                  )
                    ? values.filterValues.customFilters["Estado Remito"].map(
                        (item) => item.codEstadoRemito
                      )
                    : undefined,
                  estadoVehiculo: Array.isArray(
                    values.filterValues.customFilters["Estado Vehículo"]
                  )
                    ? values.filterValues.customFilters["Estado Vehículo"]
                        .map((item) => item.id)
                        .join(",")
                    : undefined,
                  conBloqueo: values.filterValues.customFilters[
                    "Lista Vehículos con Bloqueo"
                  ]
                    ? values.filterValues.customFilters[
                        "Lista Vehículos con Bloqueo"
                      ].id
                    : undefined,
                  conDanos: values.filterValues.customFilters[
                    "Lista Vehículos con Daños"
                  ]
                    ? values.filterValues.customFilters[
                        "Lista Vehículos con Daños"
                      ].id
                    : undefined,
                  mercado: Array.isArray(
                    values.filterValues.customFilters["Mercado"]
                  )
                    ? values.filterValues.customFilters["Mercado"].map(
                        (item) => item.codMercado
                      )
                    : undefined,
                  destinos: Array.isArray(
                    values.filterValues.customFilters["Destinos"]
                  )
                    ? values.filterValues.customFilters["Destinos"].map(
                        (item) => item.codMarca
                      )
                    : undefined,
                  origens: Array.isArray(
                    values.filterValues.customFilters["Origen"]
                  )
                    ? values.filterValues.customFilters["Origen"].map(
                        (item) => item.codOrigen
                      )
                    : undefined,
                  playas: Array.isArray(
                    values.filterValues.customFilters["Playas"]
                  )
                    ? values.filterValues.customFilters["Playas"].map(
                        (item) => item.codSubcuenta
                      )
                    : undefined,
                };
              }
              break;
            case "listadoHojaRuta":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  codCuenta: values.securityValues.account.value,
                  rangeFecha: values.filterValues.customFilters[
                    "Range Fecha Hoja de Ruta"
                  ]
                    ? values.filterValues.customFilters[
                        "Range Fecha Hoja de Ruta"
                      ]
                    : undefined,
                  cliente: values.filterValues.customFilters["Cliente"]
                    ? values.filterValues.customFilters["Cliente"].codCliente
                    : undefined,
                  tipoDespacho: values.filterValues.customFilters[
                    "Tipo Despacho"
                  ]
                    ? values.filterValues.customFilters["Tipo Despacho"].id
                    : undefined,
                  proveedorTractor: values.filterValues.customFilters[
                    "Proveedor Tractor"
                  ]
                    ? values.filterValues.customFilters["Proveedor Tractor"]
                        .codProveedor
                    : undefined,
                  estado: values.filterValues.customFilters["Estado"]
                    ? values.filterValues.customFilters["Estado"].id
                    : undefined,
                };
              }
              break;
            case "consultaOperacionPendiente":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  subCuentaDescripcion:
                    values.securityValues.subaccount.description,
                  origenSolicitud: Array.isArray(
                    values.filterValues.customFilters["Origen Solicitud"]
                  )
                    ? values.filterValues.customFilters["Origen Solicitud"].map(
                        (item) => item.id
                      )
                    : undefined,
                  codGrupoOperacion: values.filterValues.customFilters[
                    "Grupo Operacion"
                  ]
                    ? values.filterValues.customFilters["Grupo Operacion"].id
                    : undefined,
                  codOperacion: Array.isArray(
                    values.filterValues.customFilters["Operacion"]
                  )
                    ? values.filterValues.customFilters["Operacion"].map(
                        (item) => item.id
                      )
                    : undefined,
                  vin: values.filterValues.customFilters["Ingrese el VIN"],
                  remitoDespacho:
                    values.filterValues.customFilters["Remito de Despacho"],
                  archivoRegistracionWEB:
                    values.filterValues.customFilters[
                      "Archivo Registracion WEB"
                    ],
                };
              }
              break;
            case "pedidos-confirmado-jd":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  nroHr: values.filterValues.customFilters.nroHr,
                  nroPedido: values.filterValues.customFilters.nroPedido,
                };
              }
              break;
            case "consulta-general-transacciones":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  transacciones: Array.isArray(
                    values.filterValues.customFilters["Transacciones"]
                  )
                    ? values.filterValues.customFilters["Transacciones"].map(
                        (item) => item.id
                      )
                    : undefined,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                };
              }
              break;
            case "vehiculosTaller":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codTaller: values.filterValues.customFilters.Taller.id,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  estado: values.filterValues.customFilters.Estados.id,
                };
              }
              break;
            case "impressionHojaRuta":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  playas: Array.isArray(
                    values.filterValues.customFilters["Playas"]
                  )
                    ? values.filterValues.customFilters["Playas"].map(
                        (item) => item.codSubcuenta
                      )
                    : undefined,
                };
              }
              break;
            case "despachos":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  playas: Array.isArray(
                    values.filterValues.customFilters["Playas"]
                  )
                    ? values.filterValues.customFilters["Playas"].map(
                        (item) => item.codSubcuenta
                      )
                    : undefined,
                  mercado: Array.isArray(
                    values.filterValues.customFilters["Mercados"]
                  )
                    ? values.filterValues.customFilters["Mercados"].map(
                        (item) => item.id
                      )
                    : undefined,
                  origens: Array.isArray(
                    values.filterValues.customFilters["Origen"]
                  )
                    ? values.filterValues.customFilters["Origen"].map(
                        (item) => item.id
                      )
                    : undefined,
                  destinos: Array.isArray(
                    values.filterValues.customFilters["Destinos"]
                  )
                    ? values.filterValues.customFilters["Destinos"].map(
                        (item) => item.id
                      )
                    : undefined,
                  concesionarios: Array.isArray(
                    values.filterValues.customFilters["Concesionarios"]
                  )
                    ? values.filterValues.customFilters["Concesionarios"].map(
                        (item) => item.id
                      )
                    : undefined,
                  marcas: Array.isArray(
                    values.filterValues.customFilters["Marca"]
                  )
                    ? values.filterValues.customFilters["Marca"].map(
                        (item) => item.id
                      )
                    : undefined,
                };
              }
              break;
            case "consultaOt":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  estadoOt: values.filterValues.customFilters.EstadoOT.id,
                  numeroOrden: values.filterValues.customFilters.NumeroOT,
                  vin: values.filterValues.customFilters.Vin,
                  origenSolicitud: Array.isArray(
                    values.filterValues.customFilters["OrigenSolicitud"]
                  )
                    ? values.filterValues.customFilters["OrigenSolicitud"].map(
                        (item) => item.id
                      )
                    : undefined,
                  grupoOperacion: values.filterValues.customFilters
                    .GrupoOperacion
                    ? values.filterValues.customFilters.GrupoOperacion.id
                    : undefined,
                  operacion: Array.isArray(
                    values.filterValues.customFilters["Operacion"]
                  )
                    ? values.filterValues.customFilters["Operacion"].map(
                        (item) => item.id
                      )
                    : undefined,
                };
              }
              break;
            case "operacionesRealizadas":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  detallado: values.filterValues.customFilters.Detallado
                    ? values.filterValues.customFilters.Detallado.id
                    : undefined,
                  grupoOperacion: values.filterValues.customFilters
                    .GrupoOperacion
                    ? values.filterValues.customFilters.GrupoOperacion.id
                    : undefined,
                  operacion: Array.isArray(
                    values.filterValues.customFilters["Operaciones"]
                  )
                    ? values.filterValues.customFilters["Operaciones"].map(
                        (item) => item.id
                      )
                    : undefined,
                  proveedorTractor: values.filterValues.customFilters[
                    "Proveedor Tractor"
                  ]
                    ? values.filterValues.customFilters["Proveedor Tractor"].id
                    : undefined,
                  playas: Array.isArray(
                    values.filterValues.customFilters["Playas"]
                  )
                    ? values.filterValues.customFilters["Playas"].map(
                        (item) => item.codSubcuenta
                      )
                    : undefined,
                  marcas: Array.isArray(
                    values.filterValues.customFilters["Marca"]
                  )
                    ? values.filterValues.customFilters["Marca"].map(
                        (item) => item.id
                      )
                    : undefined,
                };
              }
              break;
            case "transaccionesUsuarios":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  subCuentas: Array.isArray(
                    values.filterValues.customFilters["subcuentasSelect"]
                  )
                    ? values.filterValues.customFilters["subcuentasSelect"].map(
                        (item) => item.codSubcuenta
                      )
                    : undefined,
                  codCliente: values.securityValues.client.value,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  transacciones: Array.isArray(
                    values.filterValues.customFilters["Transacciones"]
                  )
                    ? values.filterValues.customFilters["Transacciones"].map(
                        (item) => item.id
                      )
                    : undefined,
                  usuario: values.filterValues.customFilters["Usuario"]
                    ? values.filterValues.customFilters["Usuario"].id
                    : undefined,
                };
              }
              break;
            case "remitos":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  subCuentas: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  letras: values.filterValues.customFilters.Letras,
                  ptoVenta: values.filterValues.customFilters.PuntoVenta,
                  numeros: values.filterValues.customFilters.Numeros,
                  fechaLiberacion: values.filterValues.customFilters.Liberacion
                    ? values.filterValues.customFilters.Liberacion.slice(0, 2)
                    : undefined,
                  liberacionBoolean: values.filterValues.customFilters
                    .Liberacion
                    ? values.filterValues.customFilters.Liberacion[2]
                    : undefined,
                  fechaDespacho: values.filterValues.customFilters.Despacho
                    ? values.filterValues.customFilters.Despacho.slice(0, 2)
                    : undefined,
                  despachoBoolean:
                    values.filterValues.customFilters.Despacho[2],
                  fechaCargaRetorno: values.filterValues.customFilters
                    .CargaRetorno
                    ? values.filterValues.customFilters.CargaRetorno.slice(0, 2)
                    : undefined,
                  cargaRetornoBoolean:
                    values.filterValues.customFilters.CargaRetorno[2],
                  mercado: Array.isArray(
                    values.filterValues.customFilters.Mercados
                  )
                    ? values.filterValues.customFilters.Mercados.map(
                        (item) => item.id
                      )
                    : undefined,
                  incluirOpPendientes:
                    values.filterValues.customFilters
                      .IncluirOperacionesPendientes,
                  remitos: Array.isArray(
                    values.filterValues.customFilters.TipoRemito
                  )
                    ? values.filterValues.customFilters.TipoRemito.map(
                        (item) => item.id
                      )
                    : undefined,
                  estadosRemitos: Array.isArray(
                    values.filterValues.customFilters.Estados
                  )
                    ? values.filterValues.customFilters.Estados.map(
                        (item) => item.id
                      )
                    : undefined,
                  listarVehiculosDanos: values.filterValues.customFilters
                    .ListarVehiculoConDanos
                    ? values.filterValues.customFilters.ListarVehiculoConDanos
                        .id
                    : undefined,
                };
              }
              break;
            case "listadoColectas":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  vin: values.filterValues.customFilters.VIN
                    ? values.filterValues.customFilters.VIN
                    : ``,
                  estado: values.filterValues.customFilters.Estado
                    ? values.filterValues.customFilters.Estado.id
                    : undefined,
                  colecta: values.filterValues.customFilters.colecta
                    ? values.filterValues.customFilters.colecta
                    : ["", ""],
                  proveedor: values.filterValues.customFilters.Proveedor
                    ? values.filterValues.customFilters.Proveedor.id
                    : undefined,
                  orden: values.filterValues.customFilters.orden
                    ? values.filterValues.customFilters.orden.id
                    : undefined,
                };
              }
              break;
            case "listadoArmadoViajes":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  fechaLiberacion: values.filterValues.customFilters.Liberacion
                    ? values.filterValues.customFilters.Liberacion.slice(0, 2)
                    : undefined,
                  liberacionBoolean: values.filterValues.customFilters
                    .Liberacion
                    ? values.filterValues.customFilters.Liberacion[2]
                    : undefined,
                  fechaDespacho: values.filterValues.customFilters.Despacho
                    ? values.filterValues.customFilters.Despacho.slice(0, 2)
                    : undefined,
                  despachoBoolean:
                    values.filterValues.customFilters.Despacho[2],
                  fechaCargaRetorno: values.filterValues.customFilters
                    .CargaRetorno
                    ? values.filterValues.customFilters.CargaRetorno.slice(0, 2)
                    : undefined,
                  cargaRetornoBoolean:
                    values.filterValues.customFilters.CargaRetorno[2],
                  mercado: Array.isArray(
                    values.filterValues.customFilters.Mercados
                  )
                    ? values.filterValues.customFilters.Mercados.map(
                        (item) => item.id
                      )
                    : undefined,
                  remitos: Array.isArray(
                    values.filterValues.customFilters.TipoRemito
                  )
                    ? values.filterValues.customFilters.TipoRemito.map(
                        (item) => item.id
                      )
                    : undefined,
                  estadosRemitos: Array.isArray(
                    values.filterValues.customFilters.Estados
                  )
                    ? values.filterValues.customFilters.Estados.map(
                        (item) => item.id
                      )
                    : undefined,
                  concesionarios: Array.isArray(
                    values.filterValues.customFilters.Concesionarios
                  )
                    ? values.filterValues.customFilters.Concesionarios.map(
                        (item) => item.id
                      )
                    : undefined,
                  direccionEntrega: Array.isArray(
                    values.filterValues.customFilters.DireccionEntrega
                  )
                    ? values.filterValues.customFilters.DireccionEntrega.map(
                        (item) => item.id
                      )
                    : undefined,
                  radioButton: values.filterValues.customFilters.radioButton
                    ? values.filterValues.customFilters.radioButton
                    : false,
                };
              }
              break;
            case "calculoStock":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  clienteDescripcion: values.securityValues.client.description,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  codTaller: values.filterValues.customFilters.Taller
                    ? values.filterValues.customFilters.Taller.id
                    : undefined,
                  codPieza: values.filterValues.customFilters.Pieza
                    ? values.filterValues.customFilters.Pieza.id
                    : undefined,
                };
              }
              break;
            case "liberadosDespachados":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  marca: values.filterValues.customFilters.Marca
                    ? values.filterValues.customFilters.Marca.id
                    : undefined,
                  concesionario: values.filterValues.customFilters.Concesionario
                    ? values.filterValues.customFilters.Concesionario.id
                    : undefined,
                };
              }
              break;
            case "vehiculosPendientesTaller":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  codSubcuenta: values.securityValues.subaccount.value,
                  marca: values.filterValues.customFilters.Marca
                    ? values.filterValues.customFilters.Marca.id
                    : undefined,
                  marcaDescripcion: values.filterValues.customFilters.Marca
                    ? values.filterValues.customFilters.Marca.descripcion
                    : undefined,
                  vin: values.filterValues.customFilters.vin
                    ? values.filterValues.customFilters.vin.id
                    : "",
                };
              }
              break;
            case "historiaDelVin":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  codTaller: values.filterValues.customFilters.Taller
                    ? values.filterValues.customFilters.Taller.id
                    : undefined,
                  estado: values.filterValues.customFilters.Estados
                    ? values.filterValues.customFilters.Estados.id
                    : "0",
                  estadoDescripcion: values.filterValues.customFilters.Estados
                    ? values.filterValues.customFilters.Estados.descripcion
                    : undefined,
                  filtroPorFecha: values.filterValues.customFilters.radioButton
                    ? values.filterValues.customFilters.radioButton
                    : false,
                  vin: values.filterValues.customFilters.vin
                    ? values.filterValues.customFilters.vin
                    : "0",
                };
              }
              break;

            case "comprasTaller":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codCliente: values.securityValues.client.value,
                  clienteDescripcion: values.securityValues.client.description,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  codTaller: values.filterValues.customFilters.Taller
                    ? values.filterValues.customFilters.Taller.id
                    : undefined,
                  estado: values.filterValues.customFilters.Estados
                    ? values.filterValues.customFilters.Estados.id
                    : "0",
                  estadoDescripcion: values.filterValues.customFilters.Estados
                    ? values.filterValues.customFilters.Estados.descripcion
                    : undefined,
                  proveedor: values.filterValues.customFilters.Proveedor
                    ? values.filterValues.customFilters.Proveedor.id
                    : "0",
                  proveedorDescripcion: values.filterValues.customFilters
                    .Proveedor
                    ? values.filterValues.customFilters.Proveedor.descripcion
                    : "0",
                };
              }
              break;
            case "valeRepuestos":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codTaller: values.filterValues.customFilters.Taller
                    ? values.filterValues.customFilters.Taller.id
                    : undefined,
                  estado: values.filterValues.customFilters.Estados
                    ? values.filterValues.customFilters.Estados.id
                    : "0",
                  estadoDescripcion: values.filterValues.customFilters.Estados
                    ? values.filterValues.customFilters.Estados.descripcion
                    : undefined,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha.slice(0, 2)
                    : undefined,
                  bool: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha[2]
                    : undefined,
                };
              }
              break;
            case "vehiculosTaller":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codTaller: values.filterValues.customFilters.Taller
                    ? values.filterValues.customFilters.Taller.id
                    : undefined,
                  estado: values.filterValues.customFilters.Estados
                    ? values.filterValues.customFilters.Estados.id
                    : "0",
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha.slice(0, 2)
                    : undefined,
                };
              }
              break;
            case "liberacionDespachos":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha.slice(0, 2)
                    : undefined,
                  bool: values.filterValues.customFilters.bool
                    ? values.filterValues.customFilters.bool
                    : false,
                };
              }
              break;
            case "documentacionTransporte":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  origen: values.filterValues.customFilters.Origen
                    ? values.filterValues.customFilters.Origen.id
                    : undefined,
                  codProveedr: values.filterValues.customFilters.Proveedor
                    ? values.filterValues.customFilters.Proveedor.id
                    : undefined,
                  descripcionProveedor: values.filterValues.customFilters
                    .Proveedor
                    ? values.filterValues.customFilters.Proveedor.descripcion
                    : "proveedor",
                  codChofer: values.filterValues.customFilters.Chofer
                    ? values.filterValues.customFilters.Chofer.id
                    : undefined,
                  descripcionChofer: values.filterValues.customFilters.Chofer
                    ? values.filterValues.customFilters.Chofer.nombre
                    : "chofer",
                };
              }
              break;
            case "vinesPreparar":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codCliente: values.securityValues.client.value,
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  vines: values.filterValues.customFilters.Vines
                    ? values.filterValues.customFilters.Vines
                    : "",
                  vin: values.filterValues.customFilters.VIN
                    ? values.filterValues.customFilters.VIN.id
                    : "0",
                };
              }
              break;
            case "translado":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codSubcuenta: values.securityValues.subaccount.value,
                  codCliente: values.securityValues.client.value,
                  codSubcuentaDestino: values.filterValues.customFilters
                    .SubcuentaDestino
                    ? values.filterValues.customFilters.SubcuentaDestino
                        .codSubcuenta
                    : 0,
                  vin: values.filterValues.customFilters.VIN
                    ? values.filterValues.customFilters.VIN.id
                    : "0",
                  bool: values.filterValues.customFilters.Stock
                    ? values.filterValues.customFilters.Stock
                    : false,
                  codPerfil: values.filterValues.customFilters.Perfil
                    ? values.filterValues.customFilters.Perfil.id
                    : 0,
                };
              }
              break;
            case "ofertasServicio":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  estado: values.filterValues.customFilters.Estado
                    ? values.filterValues.customFilters.Estado.id
                    : "",
                  estadoDescripcion: values.filterValues.customFilters.Estado
                    ? values.filterValues.customFilters.Estado.descripcion
                    : "",
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha.slice(0, 2)
                    : undefined,
                  bool: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha[2]
                    : false,
                  codClientes: values.filterValues.customFilters.Clientes
                    ? values.filterValues.customFilters.Clientes.id
                    : undefined,
                  nroOferta: values.filterValues.customFilters.NroOferta
                    ? values.filterValues.customFilters.NroOferta
                    : "",
                };
              }
              break;
            case "transitTimePedidoPlaya":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  vin: values.filterValues.customFilters.VIN
                    ? values.filterValues.customFilters.VIN.id
                    : "",
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  nroAssignacion: values.filterValues.customFilters
                    .NroAssignacion
                    ? values.filterValues.customFilters.NroAssignacion
                    : "",
                  fechaDespacho: values.filterValues.customFilters.Despacho
                    ? values.filterValues.customFilters.Despacho.slice(0, 2)
                    : "",
                  despachoBoolean: values.filterValues.customFilters.Despacho
                    ? values.filterValues.customFilters.Despacho[2]
                    : false,
                  fechaCargaRetorno: values.filterValues.customFilters
                    .CargaRetorno
                    ? values.filterValues.customFilters.CargaRetorno.slice(0, 2)
                    : "",
                  cargaRetornoBoolean: values.filterValues.customFilters
                    .CargaRetorno
                    ? values.filterValues.customFilters.CargaRetorno[2]
                    : false,
                  concesionarios: values.filterValues.customFilters
                    .Concesionarios
                    ? values.filterValues.customFilters.Concesionarios.map(
                        (item) => item.id
                      )
                    : "",
                  direccionEntrega: values.filterValues.customFilters
                    .DirecionEntrega
                    ? values.filterValues.customFilters.DirecionEntrega.map(
                        (item) => item.id
                      )
                    : "",
                };
              }
              break;
            case "operacionesCanceladasWeb":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  codCliente: values.securityValues.client.value,
                  vin: values.filterValues.customFilters.VIN
                    ? values.filterValues.customFilters.VIN.id
                    : "",
                  alta: values.filterValues.customFilters.Alta
                    ? values.filterValues.customFilters.Alta.slice(0, 2)
                    : "",
                  altaBoolean: values.filterValues.customFilters.Alta
                    ? values.filterValues.customFilters.Alta[2]
                    : false,
                  cancelacion: values.filterValues.customFilters.Cancelacion
                    ? values.filterValues.customFilters.Cancelacion.slice(0, 2)
                    : "",
                  cancelacionBoolean: values.filterValues.customFilters
                    .Cancelacion
                    ? values.filterValues.customFilters.Cancelacion[2]
                    : false,
                  operacion: values.filterValues.customFilters.Operacion
                    ? values.filterValues.customFilters.Operacion.map(
                        (item) => item.id
                      )
                    : "",
                  grupoOperacion: values.filterValues.customFilters
                    .GrupoOperacion
                    ? values.filterValues.customFilters.GrupoOperacion.id
                    : "",
                };
              }
              break;
            case "consultaOsTerceros":
              if (
                values &&
                values.filterValues &&
                values.filterValues.customFilters
              ) {
                console.log("VALORES =>", values.filterValues.customFilters);
                reporteDto = {
                  codPais: values.securityValues.country.value,
                  codCuenta: values.securityValues.account.value,
                  vin: values.filterValues.customFilters.VIN
                    ? values.filterValues.customFilters.VIN.id
                    : "",
                  rangeFecha: values.filterValues.customFilters.Fecha
                    ? values.filterValues.customFilters.Fecha
                    : undefined,
                  descCliente: values.filterValues.customFilters.descCliente
                    ? values.filterValues.customFilters.descCliente
                    : "",
                  nroOfertaServ: values.filterValues.customFilters.nroOfertaServ
                    ? values.filterValues.customFilters.nroOfertaServ
                    : "",
                  cliente: values.filterValues.customFilters.Cliente
                    ? values.filterValues.customFilters.Cliente.id
                    : "",
                  clienteDescripcion: values.filterValues.customFilters.Cliente
                    ? values.filterValues.customFilters.Cliente.descripcion
                    : "",
                };
              }
              break;
            default:
              break;
          }
        });
      }
      let updatedData;
      try {
        const response = await sipcoAxiosService.post(
          props.filterOptions.urlApi,
          reporteDto
        );
        console.log("REQUISIÇÃO =>", props.filterOptions.urlApi);
        if (Array.isArray(response.data)) {
          updatedData = response.data.map((item) => ({
            ...item,
            optionName: `${item.descripcion}`,
          }));
        } else {
          updatedData = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      return updatedData;
    },
    [props, values]
  );

  const CustomHeader = () => (
    <div>
      <LanguageProvider id="7795" alt="Filtrar por" />
      {props.onSearch != null && (
        <div>
          <Button
            label={LanguageProvider({ id: "17722", alt: "Limpar" })}
            text
            onClick={() => setValues(emptyFilterValues)}
          />
          <Button
            label={LanguageProvider({ id: "9665", alt: "Buscar" })}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (props.filterOptions) {
                const searchResult = await searchSuggestions(null);
                props.onSearch(searchResult);
              } else {
                props.onSearch(null);
              }
            }}
          />
        </div>
      )}
    </div>
  );
  return (
    <div className="sipco-filter">
      <Accordion multiple activeIndex={[0, 1]}>
        <AccordionTab header={<CustomHeader />}>
          <Accordion multiple activeIndex={[0, 1]}>
            {props.securityOptions && (
              <AccordionTab
                header={LanguageProvider({ id: "65", alt: "Segurança" })}
              >
                <FitlerSecuritySection {...props.securityOptions} />
              </AccordionTab>
            )}
            {props.filterOptions ? (
              <AccordionTab
                header={LanguageProvider({ id: "743", alt: "Filtros" })}
              >
                <div className="filter-options">
                  {props.filterOptions.customFilters &&
                    props.filterOptions.customFilters.map(
                      (customFilter, index) => (
                        <CustomFilter
                          key={"custom-filter-" + index}
                          customFilter={customFilter}
                        />
                      )
                    )}
                </div>
              </AccordionTab>
            ) : (
              children
            )}
          </Accordion>
        </AccordionTab>
      </Accordion>
    </div>
  );
}

import React, { ReactNode, createContext, useContext } from "react";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import settings from "../../settings.json"

export default class SipcoAxiosService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: settings.BaseApiPath,
    });
  }

  async get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeaders();
    return this.axiosInstance.get(url, config);
  }

  async put(url: string, values: any): Promise<AxiosResponse> {
    this.setHeaders();
    return this.axiosInstance.put(url, values);
  }

  async post(
    endpoint: string,
    data: any,
    config: AxiosRequestConfig | null = null
  ): Promise<any> {
    this.setHeaders();
    return this.axiosInstance.post(endpoint, data, config).catch((error) => {
      if (error.response) {
        return {
          status: error.response.status,
          data: error.response.data,
        };
      } else {
        return {
          status: "Unknown",
          data: "Request failed",
        };
      }
    });
  }

  async login(userModel: {
    country: string;
    username: string;
    password: string;
  }) {
    this.setHeaders();
    return this.post("/auth/login", userModel);
  }

  private setHeaders() {
    const userData = localStorage.getItem("loginData");
    if (userData) {
      const authenticatedUser = JSON.parse(userData);
      this.axiosInstance.defaults.headers.common["Authorization"] =
        `Bearer ${authenticatedUser.jwtToken}`;
    }
  }
}

export const SipcoAxiosContext = createContext<SipcoAxiosService | null>(null);

interface SipcoAxiosProviderProps {
  children: ReactNode;
}

export const SipcoAxiosProvider = ({
  children,
}: SipcoAxiosProviderProps): JSX.Element => {
  const dcpAxiosService = new SipcoAxiosService();

  return (
    <SipcoAxiosContext.Provider value={dcpAxiosService}>
      {children}
    </SipcoAxiosContext.Provider>
  );
};

export function useSipcoAxiosService(): SipcoAxiosService {
  const dcpAxiosService = useContext(SipcoAxiosContext);
  if (!dcpAxiosService) {
    console.error("sipcoAxiosService must be used within a SipcoAxiosProvider");
  }
  return dcpAxiosService!;
}

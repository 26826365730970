import "./SearchModal.scss";

import React, { useContext, useEffect, useState } from "react";
import useAuth, { AuthContext } from "@shared/AuthContext";

import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

type SearchResult = {
  descripcion: string;
  codPais: number;
  codFuncion: string;
  screenName: string;
  screenUrl: string;
  // Add more properties as needed
};
function SearchModal() {
  const navigate = useNavigate();
  const initialResults: SearchResult[] = []; // Use an empty array if there are no initial search results
  const visitCounts = JSON.parse(localStorage.getItem("VisitCount") || "{}");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] =
    useState<SearchResult[]>(initialResults);
  const { user } = useAuth();
  const sipcoAxiosService = useSipcoAxiosService();

  useEffect(() => {
    sipcoAxiosService
      .get("/screens", {
        params: {
          codPais: user.pais.codPais,
          param: searchTerm,
        },
      })
      .then((response) => {
        const visitCounts = JSON.parse(
          localStorage.getItem("visitCounts") || "{}"
        );
        const sortedResults = response.data.sort(
          (a: SearchResult, b: SearchResult) => {
            const visitsA = visitCounts[a.codFuncion] || 0;
            const visitsB = visitCounts[b.codFuncion] || 0;
            return visitsB - visitsA;
          }
        );
        setSearchResults(sortedResults);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [searchTerm]);

  const renderFooter = () => {
    return (
      <div className={"SearchFooter"}>
        <label>
          <b>ESC</b> para salir
        </label>
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <InputText
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </div>
    );
  };
  return (
    <div>
      <Button
        label="Search"
        onClick={() => setModalIsOpen(true)}
        className="search-modal-button"
        icon={"pi pi-search icon-padding"}
      />
      <Dialog
        header={renderHeader}
        visible={modalIsOpen}
        className="search-modal-dialog"
        footer={renderFooter()}
        onHide={() => setModalIsOpen(false)}
      >
        <Divider align="left">Resultados</Divider>
        {searchResults.length > 0 ? (
          searchResults.map((item, index) => (
            <div key={index} className={"SearchItem"}>
              <BreadCrumb
                model={[
                  { label: "Menu" },
                  { label: "Consultas" },
                  {
                    label: item.screenName,
                    command: () => {
                      localStorage.setItem("pageCode", item.codFuncion); // Grava o code no localStorage
                      navigate(item.screenUrl);
                    },
                  },
                ]}
              />
              <div className="search-modal-results">
                <Link
                  to={item.screenUrl}
                  onClick={() =>
                    localStorage.setItem("pageCode", item.codFuncion)
                  }
                >
                  <h3>{item.screenName}</h3>
                </Link>
                <p>{item.descripcion}</p>
                <p>
                  <b>Cod:</b> {item.codFuncion}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="search-modal-no-result-text">
            Nenhum resultado encontrado
          </p>
        )}
      </Dialog>
    </div>
  );
}

export default SearchModal;

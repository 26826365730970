import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function LiberadosDespachados() {
  const SCREEN_CODE = "con0048_liberados_vs_despachados";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [467, 468, 396, 2941];
  const filterNames = [];
  const filterColumnsId = [
    1491, 396, 2139, 2941, 3069, 159, 164, 3186, 4791, 0, 0, 4197, 5618, 5619,
    0, 3277, 818, 16868, 10502,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 467, 468, 396, 2941, 1491, 2139, 3069, 159, 164,
    3186, 4791, 4197, 5618, 5619, 3277, 818, 16868, 10502,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "liberados-vs-despachados/report",
          parametros: ["liberadosDespachados"],
          customFilters: [
            {
              label: filterNames[0].label + filterNames[1].label,
              variable: "Fecha",
              type: "date",
            },
            {
              label: filterNames[2].label,
              variable: "Marca",
              type: "dropdown",
              options: [],
              parametros: ["codPais", "codCliente"],
              urlApi: "liberados-vs-despachados/combo/marca",
            },
            {
              label: filterNames[3].label,
              variable: "Concesionario",
              type: "dropdown",
              options: [],
              parametros: ["codPais", "codCliente"],
              urlApi: "liberados-vs-despachados/combo/concesionario",
            },
          ],
        }}
      />
      <Panel header="Liberados/Despachados" style={{ margin: "0" }}>
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ width: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          {/*<Column field="" header={columnsNames[19].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[20].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[21].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[22].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[23].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[24].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[25].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[26].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[27].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>                */}
        </DataTable>
      </Panel>
    </div>
  );
}
export default LiberadosDespachados;

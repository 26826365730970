import React, { useState } from 'react';

const Home = () => {
    const data = JSON.parse(localStorage.getItem('loginData') || '{}');
    const [showData, setShowData] = useState(false);

    return (
        <div className="home">
            <button onClick={() => setShowData(!showData)}>
                {showData ? 'Esconder Dados' : 'Exibir Dados'}
            </button>
            {showData && Object.entries(data).map(([key, value]) => {
                if (value && typeof value === 'object') {
                    return (
                        <div key={key}>
                            <h2>{key}</h2>
                            {Object.entries(value).map(([subKey, subValue]) => (
                                <p key={subKey}>{`${subKey}: ${subValue}`}</p>
                            ))}
                        </div>
                    );
                } else {
                    return <p key={key}>{`${key}: ${value}`}</p>;
                }
            })}
        </div>
    );
};

export default Home;
import "./nav-menu.scss";

import React, { useContext, useEffect, useState } from "react";
import useAuth, { AuthContext } from "@shared/AuthContext";

import Header from "../header";
import { LanguageProvider } from "@shared/components/language-provider";
import { Menubar } from "primereact/menubar";
import { NavLink } from "react-router-dom";

const NavMenu = () => {
  const [items, setItems] = useState([]);
  const { user } = useAuth();

  const menuItems = [
    //Datos Comunes
    // {
    //   label: <LanguageProvider id="TODO" alt="Datos Comunes" />,
    //   items: [
    //     {
    //       label: <LanguageProvider id="5518" alt="Playas/Talleres" />,
    //       items: [
    //         {
    //           label: (
    //             <LanguageProvider id="12476" alt="Bloqueo de ubicaciones" />
    //           ),
    //           url: "/bloqueo-ubicaciones",
    //           code: "fun0112_bloqueo_ubicaciones",
    //           template: itemRenderer,
    //         },
    //       ],
    //     },
    //   ],
    // },
    //Consultas
    {
      label: <LanguageProvider id="TODO" alt="Consultas" />,
      items: [
        {
          label: <LanguageProvider id="16641" alt="Oferta de Servicios" />,
          items: [
            {
              label: <LanguageProvider id="23234" alt="Consulta OS Terceros" />,
              url: "/consulta-os-terceros",
              code: "con0097_consulta_os_terceros",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="16814"
                  alt="Consulta Ofertas de Servicio"
                />
              ),
              url: "/ofertas-servicio",
              code: "con0066_consulta_ofertas_de_servicio",

              template: itemRenderer,
            },
          ],
        },
        {
          label: <LanguageProvider id="3124" alt="Impresion Hoja Ruta" />,
          url: "/impression-hoja-ruta",
          code: "con0046_impression_hoje_ruta",

          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="5513" alt="Listado de hojas de Ruta" />,
          url: "/listado-de-hojas-de-rutas",
          code: "con0047_listado_hoja_ruta",

          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="273" alt="Consulta Vin" />,
          url: "/consulta-vin",
          code: "con0020_vin_danios",

          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="275" alt="Remitos" />,
          url: "/remitos",
          code: "con0021_remitos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="292" alt="Despachos" />,
          url: "/despachos",
          code: "con0025_despachos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="1197" alt="Ingreso a Playas" />,
          url: "/ingresso-playa",
          code: "con0022_ingresso_playa",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="TODO" alt="Antiguedad de Stock" />,
          url: "/antiguedad-stock",
          code: "con0023_antiguedad_stock",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="274" alt="Stock" />,
          url: "/stock",
          code: "con0027_stock",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="TODO" alt="Historico de Daños" />,
          url: "/historico-danos",
          code: "con0026_historico_danos",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="1358"
              alt="Consulta de daños por checkpoint"
            />
          ),
          url: "/danios-checkpoint",
          code: "con0028_danios_x_checkpoint",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="2050"
              alt="Consulta de Transacciones por Usuario"
            />
          ),
          url: "/transacciones-usuarios",
          code: "con0033_transacciones_usuarios",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="3451" alt="Liberacion y Despachos" />,
          url: "/liberacion-despacho",
          code: "con0044_liberacion_despachos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="18487" alt="Taller" />,

          items: [
            {
              label: <LanguageProvider id="2911" alt="Stock en Taller" />,
              url: "/stock-taller",
              code: "con0042_stock_taller",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="17017" alt="Vehiculos en taller" />,
              url: "/vehiculos-taller",
              code: "con0035_vehiculos_en_taller",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2400" alt="Historia del Vin" />,
              url: "/historia-vin",
              code: "con0036_historia_del_vin",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2406" alt="Vale Repuestos" />,
              url: "/vale-repuestos",
              code: "con0037_vale_repuestos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2512" alt="Compras Taller" />,
              url: "/compras-taller",
              code: "con0038_compras_taller",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="TODO" alt="Movimento Stock" />,
              url: "/movimento-stock",
              code: "con0039_calculo_stock",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="16479" alt="Fecha Ingreso Taller" />,
              url: "/ingresso-taller",
              code: "con0043_ingreso_taller",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="14482" alt="Consulta Vines a Preparar" />
              ),
              url: "/vines-preparar",
              code: "con0062_consulta_vines_a_preparar",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="15150" alt="MACO" />,
              items: [
                {
                  label: (
                    <LanguageProvider
                      id="TODO"
                      alt="Vehiculos pendientes taller"
                    />
                  ),
                  url: "/vehiculo-pendientes-taller",
                  code: "con0049_vehiculos_pendiente_taller",

                  template: itemRenderer,
                },
              ],
            },
          ],
        },
        {
          label: (
            <LanguageProvider id="1437" alt="Consulta de Tiempos de Entrega" />
          ),
          url: "/tiempos-entrega",
          code: "con0029_tiempos_de_entrega",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="11130" alt="Consulta de Bloqueos" />,
          url: "/consulta-bloqueos",
          code: "con0053_bloqueos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="2693" alt="Operaciones" />,

          items: [
            {
              label: (
                <LanguageProvider
                  id="2837"
                  alt="Listado Operaciones Realizadas"
                />
              ),
              url: "/listado-operaciones-realizadas",
              code: "con0041_listado_operaciones_realizadas",
            },
            {
              label: <LanguageProvider id="14380" alt="Ordenes de Trabajo" />,
              url: "/consulta-ot",
              code: "con0061_consulta_de_ot",
            },
            {
              label: (
                <LanguageProvider
                  id="13209"
                  alt="Listado Operaciones Pendientes"
                />
              ),
              url: "/consulta-operaciones-Pendientes",
              code: "con0058_consulta_operaciones_pendientes",
            },
            {
              label: (
                <LanguageProvider
                  id="19252"
                  alt="Consulta Operaciones Canceladas WEB"
                />
              ),
              url: "/operaciones-canceladas-web",
              code: "con0092_consulta_operaciones_canceladas_web",
            },
          ],
        },
        {
          label: <LanguageProvider id="TODO" alt="Liberado Vs Despachado" />,
          url: "/liberados-despachados",
          code: "con0048_liberados_vs_despachados",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="10494" alt="Listado de Armado de Viajes" />
          ),
          url: "/listado-armado-viajes",
          code: "con0051_listado_armado_viajes",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="10530" alt="Documentación de Transportes" />
          ),
          url: "/documentacion-transportes",
          code: "con0047_documentacion_de_transportes",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="12038"
              alt="Consulta General de Transacciones"
            />
          ),
          url: "/general-transacciones",
          code: "con0055_consulta_general_de_transacciones",

          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="25028"
              alt="Consulta Interface JD - HR Confirmada"
            />
          ),
          url: "/pedidos-confirmados-jd",
          code: "con0100_pedidos_confirmados_jd",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="TODO" alt="Listado Colectas" />,
          url: "/listado-colectas",
          code: "con0047_listado_colectas",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="TODO" alt="Trasnf Ord" />,
          url: "/transf-ord",
          code: "con0078_transf_ord",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="TODO" alt="Consulta Traslados" />,
          url: "/translados",
          code: "con0065_consulta_traslados",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="TODO" alt="Transit Time Pedidos Playa" />
          ),
          url: "/transit-time-pedidos-playa",
          code: "con0096_transit_time_pedidos_playa",
          template: itemRenderer,
        },
      ],
    },
    //Funciones
    {
      label: <LanguageProvider id="TODO" alt="Funciones" />,

      items: [
        //Ingreso Ecom
        // {
        //   label: <LanguageProvider id="10836" alt="Ingreso Ecom" />,
        //   url: "/ingreso-ecom",
        //   code: "fun0068_ingreso_ecom",
        //   template: itemRenderer,
        // },
        // //Mantener Hojas de Ruta
        // {
        //   label: <LanguageProvider id="TODO" alt="Mantener Hojas de Ruta" />,
        //   url: "/mantener-hojas-ruta",
        //   code: "fun0090_modificacion_manual_hr",
        //   template: itemRenderer,
        // },
        // //Remitos
        // {
        //   label: <LanguageProvider id="275" alt="Remitos" />,
        //   items: [
        //     {
        //       label: (
        //         <LanguageProvider id="TODO" alt="Administración de Remitos" />
        //       ),
        //       url: "/administracion-remitos-a-concesionarios",
        //       code: "fun0043_administracion_remitos_a_concesionarios",

        //       template: itemRenderer,
        //     },
        //     {
        //       label: (
        //         <LanguageProvider
        //           id="10709"
        //           alt="Administración de Notas Fiscales"
        //         />
        //       ),
        //       url: "/administracion-notas-fiscales",
        //       code: "fun0043_administracion_notas_fiscales",
        //       template: itemRenderer,
        //     },
        //     {
        //       label: (
        //         <LanguageProvider id="25106" alt="Impresión de Checklist" />
        //       ),
        //       url: "/impresion-checklist",
        //       code: "fun0229_impresion_checklist",
        //       template: itemRenderer,
        //     },
        //   ],
        // },
        // //Carga Manual
        // {
        //   label: <LanguageProvider id="1464" alt="Carga Manual" />,
        //   items: [
        //     {
        //       label: <LanguageProvider id="1465" alt="Carga Manual del Vin" />,
        //       url: "/modificacion-vin",
        //       code: "fun0046_modificacion_vin",
        //       template: itemRenderer,
        //     },
        //     {
        //       label: (
        //         <LanguageProvider id="1478" alt="Carga Manual de Remitos" />
        //       ),
        //       url: "/carga-manual-remitos",
        //       code: "fun0069_carga_manual_remitos",
        //       template: itemRenderer,
        //     },
        //   ],
        // },
        // Operaciones
        {
          label: <LanguageProvider id="2693" alt="Operaciones" />,

          items: [
            // {
            //   label: (
            //     <LanguageProvider
            //       id="14379"
            //       alt="Generación y Cierre de Ordenes de Trabajo"
            //     />
            //   ),
            //   url: "/generacion-ot",
            //   code: "fun0114_generacion_ot",
            //   template: itemRenderer,
            // },
            // {
            //   label: (
            //     <LanguageProvider
            //       id="18312"
            //       alt="Registración de operaciones por OT"
            //     />
            //   ),
            //   url: "/registracion-operacion-por-ot",
            //   code: "fun0202_registracion_operacion_por_ot",

            //   template: itemRenderer,
            // },
            // {
            //   label: (
            //     <LanguageProvider
            //       id="18330"
            //       alt="Registración de Operaciones Automaticas"
            //     />
            //   ),
            //   url: "/registracion-operacion-automatica",
            //   code: "fun0205_registracion_operacion_automatica",

            //   template: itemRenderer,
            // },
            //essa fica
            {
              label: (
                <LanguageProvider id="TODO" alt="Cargar Operaciones Forzadas" />
              ),
              url: "/carga-operaciones-forzadas",
              code: "fun0203_carga_operaciones_forzadas",
              template: itemRenderer,
            },
            // {
            //   label: (
            //     <LanguageProvider id="18591" alt="Gestión de preparación" />
            //   ),
            //   url: "/gestion-preparacion",
            //   code: "fun0206_gestion_preparacion",
            //   template: itemRenderer,
            // },
            // {
            //   label: (
            //     <LanguageProvider id="25135" alt="Generación de Picking" />
            //   ),
            //   url: "/gen-picking",
            //   code: "fun0230_gen_picking",
            //   template: itemRenderer,
            // },
          ],
        },
        // Taller
        // {
        //   label: <LanguageProvider id="18487" alt="Taller" />,

        //   items: [
        //     {
        //       label: <LanguageProvider id="2138" alt="Ingreso Taller Manual" />,
        //       url: "/ingreso-taller-manual",
        //       code: "fun0076_ingreso_taller_manual",
        //       template: itemRenderer,
        //     },
        //     {
        //       label: <LanguageProvider id="2245" alt="Ordenes de Reparación" />,
        //       url: "/orden-reparacion",
        //       code: "fun0079_orden_reparacion",
        //       template: itemRenderer,
        //     },
        //     {
        //       label: <LanguageProvider id="320" alt="Egreso Taller" />,
        //       url: "/egreso-taller",
        //       code: "fun0054_egreso_taller",
        //       template: itemRenderer,
        //     },
        //     {
        //       label: (
        //         <LanguageProvider id="2335" alt="Mantener Vales de Repuestos" />
        //       ),
        //       url: "/mantener-vales-de-repuestos",
        //       code: "fun0081_mantener_vales_de_repuestos",
        //       template: itemRenderer,
        //     },
        //   ],
        // },
        // //Transferencias
        // {
        //   label: <LanguageProvider id="325" alt="Transferencias" />,
        //   items: [
        //     {
        //       label: (
        //         <LanguageProvider id="315" alt="Transferencia de Ubicación" />
        //       ),
        //       url: "/transferencia-ubicacion",
        //       code: "fun0040_transferencia_ubicacion",
        //       template: itemRenderer,
        //     },
        //     {
        //       label: (
        //         <LanguageProvider id="746" alt="Transferencia entre Playas" />
        //       ),
        //       url: "/transferencia-playa",
        //       code: "fun0041_transferencia_playa",
        //       template: itemRenderer,
        //     },
        //     {
        //       label: <LanguageProvider id="816" alt="Recepción de Playa" />,
        //       url: "/recepcion-de-playa",
        //       code: "fun0042_recepcion_de_playa",
        //       template: itemRenderer,
        //     },
        //     {
        //       label: (
        //         <LanguageProvider
        //           id="12840"
        //           alt="Impresión Etiquetas Transferencias"
        //         />
        //       ),
        //       url: "/impresion-etiquetas",
        //       code: "fun0201_impresion_etiquetas",
        //       template: itemRenderer,
        //     },
        //   ],
        // },
        // //Pedidos Playa
        // {
        //   label: <LanguageProvider id="22138" alt="Pedidos Playa" />,
        //   url: "/pedidos-playa",
        //   code: "fun0221_pedidos_playa",
        //   template: itemRenderer,
        // },
        // //Retorno
        // {
        //   label: <LanguageProvider id="295" alt="Retorno" />,
        //   url: "/carga-de-retornos",
        //   code: "fun0055_carga_de_retornos",
        //   template: itemRenderer,
        // },
        // //Colectas
        // {
        //   label: <LanguageProvider id="10764" alt="Colectas" />,
        //   url: "/colecta",
        //   code: "fun0103_colecta",
        //   template: itemRenderer,
        // },
        // //
        // {
        //   label: <LanguageProvider id="10835" alt="Reimpresión de Etiquetas" />,
        //   url: "/reimpresion-etiquetas",
        //   code: "fun0105_reimpresion_etiquetas",
        //   template: itemRenderer,
        // },
      ],
    },
    //M.local
    // {
    //   label: <LanguageProvider id="TODO" alt="M.local" />,
    //   items: [
    //     {
    //       label: <LanguageProvider id="TODO" alt="Despacho Mercado Local" />,
    //       url: "/despacho-mercado-nacional",
    //       code: "fun0052_despacho_mercado_nacional",
    //       template: itemRenderer,
    //     },
    //     {
    //       label: <LanguageProvider id="10215" alt="Despacho Terceros" />,
    //       url: "/despacho-terceros",
    //       code: "fun0100_despacho_terceros",
    //       template: itemRenderer,
    //     },
    //   ],
    // },
    //Importación/Exportación
    // {
    //   label: <LanguageProvider id="2442" alt="Importación/Exportación" />,
    //   items: [
    //     {
    //       label: <LanguageProvider id="13525" alt="Liberación Aduana" />,
    //       url: "/liberacion-aduana",
    //       code: "fun0060_liberacion_aduana",
    //       template: itemRenderer,
    //     },
    //     {
    //       label: (
    //         <LanguageProvider id="1085" alt="Disponibilidad de Importación" />
    //       ),
    //       url: "/disponibilidad-importacion",
    //       code: "fun0061_disponibilidad_importacion",
    //       template: itemRenderer,
    //     },
    //     {
    //       label: <LanguageProvider id="1052" alt="Llegada de Buque o Camion" />,
    //       url: "/llegada-buque-camion",
    //       code: "fun0058_llegada_buque_camion",
    //       template: itemRenderer,
    //     },
    //     {
    //       label: <LanguageProvider id="310" alt="Despacho Internacional" />,
    //       url: "/despacho-mercado-exportacion",
    //       code: "fun0063_despacho_mercado_exportacion",
    //       template: itemRenderer,
    //     },
    //   ],
    // },
    // //Calidad
    // {
    //   label: <LanguageProvider id="TODO" alt="Calidad" />,
    //   items: [
    //     {
    //       label: <LanguageProvider id="252" alt="Carga de Daños" />,
    //       url: "/carga-danos",
    //       code: "fun0059_carga_danos",
    //       template: itemRenderer,
    //     },
    //   ],
    // },
    // //Herramientas
    // {
    //   label: <LanguageProvider id="TODO" alt="Herramientas" />,
    //   items: [
    //     {
    //       label: <LanguageProvider id="TODO" alt="Remitos Eletrónicos" />,
    //       url: "/remito-electronico",
    //       code: "fun0101_remito_electronico",
    //       template: itemRenderer,
    //     },
    //   ],
    // },
  ];

  const setMenuVisibility = (menuArray) => {
    const { permisos } = user;
    // Função para verificar permissões
    const checkPermissions = (code) => {
      if (!permisos || !Array.isArray(permisos)) {
        return false;
      }
      return permisos.includes(code);
    };
    // Função recursiva para verificar visibilidade de um menu e seus submenus
    const checkVisibility = (menuItem) => {
      let hasVisibleChild = false;

      // Se o menu tem submenus, aplica a função recursivamente
      if (menuItem.items && Array.isArray(menuItem.items)) {
        menuItem.items = menuItem.items.map((subItem) =>
          checkVisibility(subItem)
        );
        // Verifica se algum submenu é visível
        hasVisibleChild = menuItem.items.some((item) => item.visible);
      }

      // Define a visibilidade do menu baseado nas permissões e se há submenus visíveis
      const isVisible = checkPermissions(menuItem.code) || hasVisibleChild;

      return {
        ...menuItem,
        visible: isVisible,
      };
    };
    // Aplica a função recursivamente para todos os menus
    return menuArray.map((menuItem) => checkVisibility(menuItem));
  };

  const updatedMenuArray = setMenuVisibility(menuItems);

  useEffect(() => {
    setItems(updatedMenuArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Header-Nav-Menu">
      <Header />
      <div className="nav-menu">
        <Menubar model={items} />
      </div>
    </div>
  );
};

const itemRenderer = (item) => (
  <NavLink
    className="flex align-items-center p-menuitem-link"
    to={item.url}
    onClick={(e) => {
      localStorage.setItem("pageCode", item.code);
    }}
  >
    <span className="mx-2">{item.label}</span>
  </NavLink>
);

export default NavMenu;

import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ListadoColectas() {
  const SCREEN_CODE = "con0047_listado_colectas";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [16453, 11787, 1491, 3344, 11370, 5686];
  const filterNames = [];

  const filterColumnsId = [
    10758, 5686, 2941, 3069, 164, 159, 1491, 396, 2139, 2140, 2898, 1316, 10747,
    4675, 4791, 1269, 2521, 2521, 10766, 10774, 21275, 1736, 990, 991, 5701,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    5239, 3754, 10758, 5686, 2941, 3069, 164, 159, 1491, 396, 2139, 2140, 2898,
    1316, 10747, 4675, 4791, 1269, 2521, 10766, 10774, 11247, 748, 749, 4791,
    3344, 10764, 11787, 16453, 1, 2, 32, 54, 65, 396, 408, 467, 468, 491, 748,
    749, 1182, 1357, 1396, 1491, 1551, 1552, 1791, 2496, 2502, 2941, 4655, 4870,
    4871, 5044, 5583, 6590, 7811, 10021, 10531, 10540, 10774, 11070, 11078,
    11097, 11314, 14712, 16243, 16246, 16505, 16531, 16544, 16576, 16808, 16809,
    21275, 18588, 1736, 990, 991, 5701, 188, 16239, 10043, 10044, 11370,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "listado-colectas/report",
          parametros: ["listadoColectas"],
          customFilters: [
            { label: filterNames[0].label, variable: "Fecha", type: "date" },
            {
              label: filterNames[1].label,
              variable: "Proveedor",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "listado-colectas/combo/proveedor",
            },
            { label: filterNames[2].label, variable: "VIN", type: "text" },
            {
              label: filterNames[3].label,
              variable: "Estado",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "listado-colectas/combo/estado",
            },
            {
              label: filterNames[4].label,
              variable: "orden",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "listado-colectas/combo/orden",
            },
            {
              label: filterNames[5].label,
              variable: "colecta",
              type: "textRange",
            },
          ],
        }}
      />
      <Panel header="Listado de Colectas para Transportadora">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ListadoColectas;

import React, { useCallback, useEffect, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import ConsultaOsTerceros from "@pages/queries/consulta-os-terceros/ConsultaOsTerceros";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { customFilter } from "../filter";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function CustomFilter(props: {
  customFilter: customFilter;
}): React.ReactElement {
  const { values, setValues } = useFilterContext();
  const [isCalendarEnabled, setIsCalendarEnabled] = useState(false);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [dates, setDates] = useState<Date[]>([new Date(), new Date()]);
  const [customFilterValue, setCustomFilterValue] = useState([]);
  const [options, setOptions] = useState([]);
  const sipcoAxiosService = useSipcoAxiosService();
  useEffect(() => {
    setCustomFilterValue(
      values.filterValues.customFilters[props.customFilter.variable] ?? []
    );
  }, [props.customFilter.variable, values]);

  const updateFilterValues = useCallback(
    (value: any) => {
      setValues((prevValues) => ({
        ...prevValues,
        filterValues: {
          ...prevValues.filterValues,
          customFilters: {
            ...prevValues.filterValues.customFilters,
            [props.customFilter.variable]: value,
          },
        },
      }));
      console.log("UPDATE FILTER VALUES =>", value);
    },
    [props.customFilter.variable, setValues]
  );
  useEffect(() => {
    if (props.customFilter.variable === "Cliente") {
      if (options[0]) {
        updateFilterValues(options[0]);
      }
    }
  }, [options, updateFilterValues]);
  useEffect(() => {
    if (props.customFilter.type === "date" && dates[0] && dates[1]) {
      const startDate = dates[0].toISOString();
      const endDate = dates[1].toISOString();
      if (
        Array.isArray(customFilterValue) &&
        (!customFilterValue.includes(startDate) ||
          !customFilterValue.includes(endDate))
      ) {
        updateFilterValues([startDate, endDate]);
      }
    }
  }, [dates, customFilterValue, props.customFilter.type, updateFilterValues]);
  useEffect(() => {
    if (props.customFilter.type === "checkboxDate" && dates[0] && dates[1]) {
      const startDate = dates[0].toISOString();
      const endDate = dates[1].toISOString();
      if (
        Array.isArray(customFilterValue) &&
        (!customFilterValue.includes(startDate) ||
          !customFilterValue.includes(endDate))
      ) {
        updateFilterValues([startDate, endDate, false]);
      }
    }
  }, [dates, customFilterValue, props.customFilter.type, updateFilterValues]);
  const searchSuggestions = useCallback(
    async (event) => {
      let params = new URLSearchParams();
      if (props.customFilter && props.customFilter.parametros) {
        console.log("Requested Params => ", props.customFilter.parametros);
        console.log("Available Params => ", values);
        props.customFilter.parametros.forEach((parametro) => {
          switch (parametro) {
            case "codPais":
              if (values.securityValues && values.securityValues.country) {
                params.append(
                  "codPais",
                  values.securityValues.country.value.toString()
                );
              }
              break;
            case "codCliente":
              params.append("codCliente", values.securityValues.client.value);
              break;
            case "codCuenta":
              params.append(
                "codCuenta",
                values.securityValues.account.value.toString()
              );
              break;
            case "codSubcuenta":
              params.append(
                "codSubcuenta",
                values.securityValues.subaccount.value.toString()
              );
              break;
            case "descripcion":
              params.append("descripcion", event.query ? event.query : "");
              break;
            case "VIN":
              params.append(
                "vin",
                values.filterValues.customFilters.VIN
                  ? values.filterValues.customFilters.VIN
                  : "0"
              );
              break;
            case "grupoOperacion":
              params.append(
                "grupoOperacion",
                values.filterValues.customFilters.GrupoOperacion.id
              );
              break;
            case "codMarca":
              if (
                values.filterValues.customFilters &&
                values.filterValues.customFilters["Marca"]
              ) {
                params.append(
                  "codMarca",
                  values.filterValues.customFilters["Marca"].id
                );
              }
              break;
            case "Marca":
              if (
                values.filterValues.customFilters &&
                values.filterValues.customFilters["Marca"]
              ) {
                values.filterValues.customFilters["Marca"].forEach((x) => {
                  params.append("marcas", x.descripcion);
                });
              }
              break;
            case "codConcesionario":
              if (
                values.filterValues.customFilters &&
                values.filterValues.customFilters["Concesionarios"]
              ) {
                params.append(
                  "codConcesionario",
                  values.filterValues.customFilters["Concesionarios"].id
                );
              }
              break;
            case "codConcesionarios":
              if (
                values.filterValues.customFilters &&
                values.filterValues.customFilters["Concesionarios"]
              ) {
                params.append(
                  "codConcesionario",
                  values.filterValues.customFilters["Concesionarios"]
                    .map((x: any) => x.id)
                    .join(",")
                );
              }
              break;
            case "Origen":
              if (
                values.filterValues.customFilters &&
                values.filterValues.customFilters["Origen"]
              ) {
                params.append(
                  "Origen",
                  values.filterValues.customFilters["Origen"].id
                );
              }
            default:
              break;
          }
        });
        //console.log("CUSTOM PARAMETROS =>", params.toString());
      }

      const fetchResponse = await sipcoAxiosService.get(
        props.customFilter.urlApi,
        { params }
      );
      if (fetchResponse.status === 200) {
        let filteredData = [];
        filteredData = fetchResponse.data
          .map((x: any) => {
            let finalDescription = x.descripcion ? x.descripcion : x.label;
            finalDescription = x.nombre ? x.nombre : finalDescription;
            if (x.descripcionFull) {
              finalDescription = x.descripcionFull;
            }
            if (x.nombre) {
              finalDescription = x.nombre
                ? `${x.nombre} (${x.id ? x.id : x[Object.keys(x)[0]]})`
                : "invalido";
            }
            if (
              !x.descripcionFull &&
              !x.descripcion &&
              !x.nombre &&
              !x.label &&
              x.id
            ) {
              finalDescription = x.id ? x.id : "invalido";
            }
            if (props.customFilter.descripcionAddCode) {
              finalDescription = x.descripcion
                ? `${x.descripcion} (${x.id ? x.id : x[Object.keys(x)[0]]})`
                : `${x.label} (${x[Object.keys(x)[0]]})`;
            }
            // console.log("NOMBRE =>",finalDescription);
            return {
              ...x,
              optionName: finalDescription,
            };
          })
          .filter((x: any) =>
            x.optionName?.toLowerCase().includes(event.query.toLowerCase())
          );
        if (props.customFilter.optionAll) {
          filteredData.unshift({
            optionName: "TODOS",
            descripcion: "TODOS",
            id: 0,
          });
        }
        setOptions(filteredData);
      }
    },
    [props.customFilter.parametros, props.customFilter.urlApi, values]
  );

  function handleCheckBoxDateCalendarChangeValue(e: any) {
    if (e.value && e.value[0] && e.value[1]) {
      e.value[0].setHours(0, 0, 0, 0);
      e.value[1].setHours(23, 59, 59, 999);
      const startDate = new Date(e.value[0]).toISOString();
      const endDate = new Date(e.value[1]).toISOString();
      updateFilterValues([startDate, endDate, !isCalendarEnabled.toString()]);
      setDates(e.value);
    }
  }

  function handleCalendarChangeValue(e: any) {
    if (e.value !== null) {
      if (e.value[0] && e.value[1]) {
        const startDate = new Date(e.value[0]);
        const endDate = new Date(e.value[1]);
        updateFilterValues([startDate, endDate]);
      }
      setDates(e.value);
    }
  }

  switch (props.customFilter.type) {
    case "checkbox":
      return (
        <div className="RadioButtonFilter">
          <Checkbox
            id={props.customFilter.variable}
            checked={isCheckboxEnabled}
            onChange={(e) => {
              setIsCheckboxEnabled(e.checked);
              updateFilterValues(!isCheckboxEnabled);
            }}
            style={{ justifyContent: "center", position: "relative" }}
          />
          <label htmlFor={props.customFilter.variable}>
            {props.customFilter.label}
          </label>
        </div>
      );
    case "radiobuttonDate":
      return (
        <div className="sipco-option">
          <label htmlFor={props.customFilter.variable}>
            {props.customFilter.label}
          </label>
          <div className="RadioButtonFilter">
            {props.customFilter.options.map((option, index) => (
              <div key={index}>
                <RadioButton
                  id={`${props.customFilter.variable}-${index}`}
                  value={option}
                  placeholder={props.customFilter.variable}
                  name={props.customFilter.variable}
                  onChange={(e) => updateFilterValues(e.value)}
                  checked={customFilterValue.includes(option)}
                />
                <label htmlFor={`${props.customFilter.variable}-${index}`}>
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
      );
    case "checkboxDate":
      return (
        <div className={"sipco-option"}>
          <div className="RadioButtonFilter">
            <Checkbox
              id={props.customFilter.variable}
              checked={isCalendarEnabled}
              onChange={(e) => {
                setIsCalendarEnabled(e.checked);
                if (dates[0] && dates[1]) {
                  dates[0].setHours(0, 0, 0, 0);
                  dates[1].setHours(23, 59, 59, 999);
                  const startDate = new Date(dates[0]).toISOString();
                  const endDate = new Date(dates[1]).toISOString();
                  updateFilterValues([startDate, endDate, !isCalendarEnabled]);
                }
              }}
            />
            <label htmlFor={props.customFilter.variable}>
              {props.customFilter.label}
            </label>
          </div>
          <Calendar
            id={props.customFilter.variable}
            value={dates}
            selectionMode="range"
            onChange={handleCheckBoxDateCalendarChangeValue}
            disabled={!isCalendarEnabled}
            dateFormat="dd/mm/yy"
            hideOnRangeSelection
          />
        </div>
      );
    case "date":
      return (
        <div className={"sipco-option"}>
          <label>{props.customFilter.variable}</label>
          <Calendar
            id={props.customFilter.variable}
            value={dates}
            selectionMode="range"
            onChange={handleCalendarChangeValue}
            dateFormat="dd/mm/yy"
            hideOnRangeSelection
          />
        </div>
      );
    case "radioButton":
      return (
        <div className="sipco-option">
          <label htmlFor={props.customFilter.variable}>
            {props.customFilter.label}
          </label>
          <div className="RadioButtonFilter">
            {props.customFilter.options.map((option, index) => (
              <div key={index}>
                <RadioButton
                  id={`${props.customFilter.variable}-${index}`}
                  value={option}
                  placeholder={props.customFilter.variable}
                  name={props.customFilter.variable}
                  onChange={(e) => updateFilterValues(e.value)}
                  checked={customFilterValue.includes(option)}
                />
                <label htmlFor={`${props.customFilter.variable}-${index}`}>
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
      );
    case "dropdown":
      return (
        <div className={"sipco-option"}>
          <label htmlFor={props.customFilter.variable}>
            {props.customFilter.label}
          </label>
          <AutoComplete
            id={props.customFilter.variable}
            value={
              values.filterValues.customFilters[props.customFilter.variable]
            }
            suggestions={options}
            completeMethod={searchSuggestions}
            onChange={(e) => updateFilterValues(e.value)}
            field="optionName"
            dropdown
          />
        </div>
      );
    case "multipleSelection":
      return (
        <div className={"sipco-option"}>
          <label htmlFor={props.customFilter.variable}>
            {props.customFilter.label}
          </label>
          <AutoComplete
            id={props.customFilter.variable}
            value={
              values.filterValues.customFilters[props.customFilter.variable]
            }
            suggestions={options}
            completeMethod={searchSuggestions}
            onChange={(e) => updateFilterValues(e.value)}
            field="optionName"
            dropdown
            multiple={true}
          />
        </div>
      );
    case "text":
      return (
        <div className={"sipco-option"}>
          <label htmlFor={props.customFilter.label}>
            {props.customFilter.label}
          </label>
          <InputText
            id={props.customFilter.variable}
            value={
              values.filterValues.customFilters[props.customFilter.variable]
            }
            onChange={(e) => updateFilterValues(e.target.value)}
          />
        </div>
      );
    case "textRange":
      return (
        <div className={"RadioButtonFilter"}>
          <label>{props.customFilter.variable}</label>
          <InputText
            placeholder={props.customFilter.variable}
            id={`${props.customFilter.variable}-from`}
            value={customFilterValue.length > 0 ? customFilterValue[0] : ""}
            onChange={(e) => {
              const newValue = [...customFilterValue];
              newValue[0] = e.target.value;
              updateFilterValues(newValue);
            }}
            style={{ width: "25%" }}
          />

          <label>até </label>
          <InputText
            placeholder={props.customFilter.variable}
            id={`${props.customFilter.variable}-to`}
            value={customFilterValue.length > 1 ? customFilterValue[1] : ""}
            onChange={(e) => {
              const newValue = [...customFilterValue];
              newValue[1] = e.target.value;
              updateFilterValues(newValue);
            }}
            style={{ width: "25%" }}
          />
        </div>
      );
    case "dropdownRange":
      return (
        <div className={"sipco-option"}>
          <label htmlFor={props.customFilter.variable}>
            {props.customFilter.label}
          </label>
          <AutoComplete
            id={props.customFilter.variable}
            value={
              values.filterValues.customFilters[
                props.customFilter.variable + "Desde"
              ]
            }
            suggestions={options}
            completeMethod={searchSuggestions}
            onChange={(e) => updateFilterValues(e.value)} // Alterado de e.target.value para e.value
            field="optionName"
            dropdown
          />
          <AutoComplete
            id={props.customFilter.variable}
            value={
              values.filterValues.customFilters[
                props.customFilter.variable + "Hasta"
              ]
            }
            suggestions={options}
            completeMethod={searchSuggestions}
            onChange={(e) => updateFilterValues(e.value)} // Alterado de e.target.value para e.value
            field="optionName"
            dropdown
          />
        </div>
      );

    default:
      return (
        <div className={"sipco-option"}>
          <InputText
            placeholder={props.customFilter.variable}
            id={props.customFilter.variable}
            value={customFilterValue.length > 0 ? customFilterValue[0] : ""}
            onChange={(e) => updateFilterValues([e.target.value])}
          />
        </div>
      );
  }
}

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import React from "react";
import {LanguageProvider} from "@shared/components/language-provider";
import {convertDateObjects} from "@shared/components/utils/convertDateObjects";
const Messages:  React.FC<{ vinData: any }> = ({ vinData }) => {

    const filterLabelId =[]
    const filterNames = [];
    const filterColumnsId = [0,3703,3705,3709,275,13706,5271,927]
    const columnsNames = [];
    const data =  convertDateObjects(vinData?.reporteMensagens.filas);
    for (let i = 0; i < filterLabelId.length; i++) {
        filterNames.push({
            id: filterLabelId[i],
            label: LanguageProvider({ id: filterLabelId[i], alt: "Error Filter Labels" })
        });
    }
    for (let i = 0; i < filterColumnsId.length; i++) {
        columnsNames.push({
            id: filterColumnsId[i],
            label: LanguageProvider({ id: filterColumnsId[i] , alt: "Error Columns Labels"})
        });
    }

    return (
        <div className="Messages">
            <Panel >
                <DataTable size={"small"} showGridlines stripedRows  value={data} paginator scrollHeight="flex" filterDisplay="row" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}" style={{maxWidth:"100%"}} >
                    <Column field="1" sortable filter header="Fecha Processo"></Column>
                    <Column field="2" sortable filter header="Mensajes"></Column>
                    <Column field="3" sortable filter header="Fecha Llegada Mensaje"></Column>
                    <Column field="4" sortable filter header="Remitos"></Column>
                    <Column field="5" sortable filter header="Usuario Ultima Modificacion"></Column>
                    <Column field="7" sortable filter header="Archivo"></Column>
                </DataTable>
            </Panel>
        </div>
    );
};
export default Messages
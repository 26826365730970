import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ConsultaOsTerceros() {
  const SCREEN_CODE = "con0097_consulta_os_terceros";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [16563, 18997, 6256, 1491, 54];
  const filterNames = [];
  const filterColumnsId = [
    4245, 5715, 4891, 5720, 1491, 2892, 10774, 2496, 396,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 2612, 1184, 1182, 5044, 54, 1791, 4791, 327, 467, 468, 1140, 2020,
    2607, 2609, 2610, 2608, 2890, 16531, 448, 16563, 7795, 18997, 2621, 2622,
    901, 3600, 8388, 6256, 22833, 1669, 22796, 1491, 4724, 396, 10746, 22908,
    22909, 16817, 23241, 22893, 22895, 14712, 1342, 2521, 23242, 1480, 653,
    22356, 5496, 5490, 16590,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "/consulta-os-terceros/report",
          parametros: ["consultaOsTerceros"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "nroOfertaServ",
              type: "text",
            },
            {
              label: filterNames[1].label,
              variable: "descCliente",
              type: "text",
            },
            { label: filterNames[2].label, variable: "Fecha", type: "date" },
            {
              label: filterNames[3].label,
              variable: "VIN",
              type: "dropdown",
              parametros: ["VIN", "codPais", "codCuenta"],
              urlApi: "/consulta-os-terceros/combo/vin",
            },
            {
              label: filterNames[4].label,
              variable: "Cliente",
              type: "dropdown",
              optionAll: true,
              parametros: [],
              urlApi: "/consulta-os-terceros/combo/cliente",
            },
          ],
        }}
      />
      <Panel header="Consulta OS Terceros">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOsTerceros;

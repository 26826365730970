import React, { useContext, useRef, useState } from "react";
import useAuth, { AuthContext } from "@shared/AuthContext";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function TiemposEntrega() {
  const SCREEN_CODE = "con0029_tiempos_de_entrega";
  const { user } = useAuth();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [1436, 1342, 10774, 266, 396, 10904];
  const filterNames = [];
  const filterColumnsId = [
    1491, 1, 1, 2941, 1, 2892, 1, 1, 1284, 1283, 9934, 1, 1, 1, 490, 1446, 1447,
    1448, 9809, 396, 1, 17759,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 275, 367, 396, 490, 1182, 1283, 1284, 1436, 1446, 1447, 1448, 1491,
    1791, 2621, 2622, 2892, 2941, 5044, 5583, 9809, 9934, 10898, 10904, 17759,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    tipoFecha: true,
    fecha: [],
    mercadoDesde: null,
    mercadoHasta: null,
    concecionarioDesde: null,
    concecionarioHasta: null,
    marcaDesde: null,
    marcaHasta: null,
    direccionEntregaDesde: null,
    direccionEntregaHasta: null,
  });

  const [loading, setLoading] = useState(false);
  const [mercados, setMercados] = useState([]);
  const [concesionarios, setConcesionarios] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [dirEntrega, setDirEntrega] = useState([]);

  async function searchMercados(event: any) {
    await loadMercados(event.query);
  }
  async function loadMercados(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/tiempo-entrega/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setMercados(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMercados(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchConcesionarios(event: any) {
    await loadConcesionarios(event.query);
  }
  async function loadConcesionarios(query = "") {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/tiempo-entrega/concesionario",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setConcesionarios(
              data.filter((x: any) =>
                x.descripcionFull.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setConcesionarios(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchMarcas(event: any) {
    await loadMarcas(event.query);
  }
  async function loadMarcas(query = "") {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/tiempo-entrega/marcas",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setMarcas(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMarcas(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchDirEntrega(event: any) {
    await loadDirEntrega(event.query);
  }
  async function loadDirEntrega(query = "") {
    try {
      if (
        filter.concecionarioDesde &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const selectedConcesionario = filter.concecionarioDesde;
        const { status, data } = await sipcoAxiosService.get(
          "/tiempo-entrega/direccion-entrega",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codConsecionarioDesde: selectedConcesionario.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setDirEntrega(
              data.filter((x: any) =>
                x.descripcionFull.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setDirEntrega(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "tiempo-entrega/report",
        {
          codPais: securityFilters?.securityValues?.country?.value || null,
          codCliente: securityFilters?.securityValues?.client?.value || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.value || null,
          rangeFecha: filter?.fecha || [],
          mercadoDesde: filter?.mercadoDesde?.id || null,
          mercadoHasta: filter?.mercadoHasta?.id || null,
          concesionarioDesde: filter?.concecionarioDesde?.id || null,
          concesionarioHasta: filter?.concecionarioHasta?.id || null,
          marcaDesde: filter?.marcaDesde?.id || null,
          marcaHasta: filter?.marcaHasta?.id || null,
          dirEntregaDesde: filter?.direccionEntregaDesde?.id || null,
          dirEntregaHasta: filter?.direccionEntregaHasta?.id || null,
          fechaPor: filter?.tipoFecha || false,
          codUsuario: user.userName,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="RadioButtonFilter">
              <RadioButton
                checked={filter.tipoFecha}
                onChange={(e) => {
                  setFilter({ ...filter, tipoFecha: true });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={"1284"} alt="Liberação" />
              </label>
              <RadioButton
                checked={!filter.tipoFecha}
                onChange={(e) => {
                  setFilter({ ...filter, tipoFecha: false });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={"275"} alt="Remitos" />
              </label>
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"2131"} alt="Fecha" />
              </label>
              <Calendar
                value={filter.fecha}
                selectionMode="range"
                onChange={(e) => {
                  setFilter({ ...filter, fecha: e.value });
                }}
                dateFormat="dd/mm/yy"
                hideOnRangeSelection
                numberOfMonths={2}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"367"} alt="Mercado" />
              </label>
              <AutoComplete
                value={filter.mercadoDesde}
                suggestions={mercados}
                completeMethod={searchMercados}
                onChange={(e) =>
                  setFilter({ ...filter, mercadoDesde: e.value })
                }
                field="label"
                dropdown
                disabled={!securityFilters.securityValues.country}
              />
              <AutoComplete
                value={filter.mercadoHasta}
                suggestions={mercados}
                completeMethod={searchMercados}
                onChange={(e) =>
                  setFilter({ ...filter, mercadoHasta: e.value })
                }
                field="label"
                dropdown
                disabled={!securityFilters.securityValues.country}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"2941"} alt="Concesionario" />
              </label>
              <AutoComplete
                value={filter.concecionarioDesde}
                suggestions={concesionarios}
                completeMethod={searchConcesionarios}
                onChange={(e) =>
                  setFilter({ ...filter, concecionarioDesde: e.value })
                }
                field="descripcionFull"
                dropdown
                disabled={!securityFilters.securityValues.country}
              />
              <AutoComplete
                value={filter.concecionarioHasta}
                suggestions={concesionarios}
                completeMethod={searchConcesionarios}
                onChange={(e) =>
                  setFilter({ ...filter, concecionarioHasta: e.value })
                }
                field="descripcionFull"
                dropdown
                disabled={!securityFilters.securityValues.country}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"23888"} alt="Marca" />
              </label>
              <AutoComplete
                value={filter.marcaDesde}
                suggestions={marcas}
                completeMethod={searchMarcas}
                onChange={(e) => setFilter({ ...filter, marcaDesde: e.value })}
                field="label"
                dropdown
                disabled={!securityFilters.securityValues.country}
              />
              <AutoComplete
                value={filter.marcaHasta}
                suggestions={marcas}
                completeMethod={searchMarcas}
                onChange={(e) => setFilter({ ...filter, marcaHasta: e.value })}
                field="label"
                dropdown
                disabled={!securityFilters.securityValues.country}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"10904"} alt="Dir. Entrega" />
              </label>
              <AutoComplete
                value={filter.direccionEntregaDesde}
                suggestions={dirEntrega}
                completeMethod={searchDirEntrega}
                onChange={(e) =>
                  setFilter({ ...filter, direccionEntregaDesde: e.value })
                }
                field="descripcionFull"
                dropdown
                disabled={
                  !securityFilters.securityValues.country ||
                  !filter.concecionarioDesde
                }
              />
              <AutoComplete
                value={filter.direccionEntregaHasta}
                suggestions={dirEntrega}
                completeMethod={searchDirEntrega}
                onChange={(e) =>
                  setFilter({ ...filter, direccionEntregaHasta: e.value })
                }
                field="descripcionFull"
                dropdown
                disabled={
                  !securityFilters.securityValues.country ||
                  !filter.concecionarioDesde
                }
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Tiempos de Entrega</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"TiempoEntrega"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          <Column field="" header={columnsNames[19].label} sortable filter />
          <Column field="" header={columnsNames[20].label} sortable filter />
          <Column field="" header={columnsNames[21].label} sortable filter />
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default TiemposEntrega;

import "./BloqueoUbicaciones.scss";
import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";

import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Fieldset } from "primereact/fieldset";
import { Divider } from "primereact/divider";
import { Chip } from "primereact/chip";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import PanelCustom from "../../../components/panel-custom/PanelCustom";

export function BloqueoUbicaciones() {
  const SCREEN_CODE = "fun0112_bloqueo_ubicaciones";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const columnsIds1 = [
    990, 991, 992, 72, 13706, 5271

  ];
 

  const columnsNames1 = filterColumnsIds(columnsIds1);
  

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  return (
    <div className="bloqueo-ubicaciones">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"1269"} alt="Ubicación" />}
        >
          <div className="filter-options">
            <div className="sipco-options-line" >
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11554"} alt="Calle Inicio" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11557"} alt="Calle Fin" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11555"} alt="Columna Inicio" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11558"} alt="Columna Fin" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
            
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id="12476" alt="Bloqueo de ubicaciones" />}
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={reporte}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="row"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames1.map((colum, index) => (
              <Column key={index} header={colum.label} sortable filter />
            ))}
          </DataTable>          
        </div>

        <Divider />
        <div>
              <Button
                label={LanguageProvider({
                  id: "12477",
                  alt: "Bloquear Ubicaciones",
                })}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "12478",
                  alt: "Desbloquear Ubicaciones",
                })}
                text
              />
              
            </div>
      </Fieldset>
    </div>
  );
}
export default BloqueoUbicaciones;

import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import PanelHeader from "@shared/components/utils/PanelHeader";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function TiemposEntrega() {
  const SCREEN_CODE = "con0055_consulta_general_de_transacciones";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const columns = [];
  const filterLabelId = [466, 467, 468];
  const filterNames = [];
  const filterColumnsId = [
    31, 4589, 1491, 16495, 16496, 21428, 3753, 3753, 12103, 21412,
  ];
  const columnsNames = [];

  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 4791, 466, 467, 468, 1002, 1182, 31, 4589, 1491,
    16495, 16496, 21427, 21428, 748, 749, 16501, 3753, 12103, 21412,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/consulta-general-transacciones",
          parametros: ["consulta-general-transacciones"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Transacciones",
              type: "multipleSelection",
              parametros: ["codPais", "descripcion"],
              urlApi: "combo-transacciones",
            },
            {
              label: filterNames[1].label + filterNames[2].label,
              variable: "Fecha",
              type: "date",
            },
          ],
        }}
      />
      <Panel
        header={
          <PanelHeader
            panelName="Consulta General de Transacciones"
            dt={dt}
            data={data}
            columnsNames={columnsNames}
          />
        }
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          {/*<Column field="5" header={columnsNames[5].label} sortable filter/>*/}
          {/*<Column field="6" header={columnsNames[6].label} sortable filter/>*/}
          {/*<Column field="7" header={columnsNames[7].label} sortable filter/>*/}
          {/*<Column field="8" header={columnsNames[8].label} sortable filter/>*/}
          {/*<Column field="9" header={columnsNames[9].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[10].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[11].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[12].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[13].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default TiemposEntrega;

import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function Remitos() {
  const SCREEN_CODE = "con0021_remitos";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [
    849, 851, 850, 1492, 1284, 382, 295, 17638, 1617, 10774, 1151, 1417,
  ];
  const filterNames = [];
  const filterColumnsId = [
    2131, 54, 266, 1491, 9428, 9620, 14731, 14722, 2375, 13534, 14735, 932,
    2892, 3344, 4216, 8299, 10774, 2496, 396, 2139, 2140, 1492, 1285, 10540,
    16243, 16505, 292, 295, 16926, 1617, 16757, 13338, 16288, 2892, 1617, 159,
    164, 3186, 10502, 13594, 1144, 1284, 16868, 16869, 5720, 8642, 2015, 818,
    1847, 10495, 2015, 1847, 754, 4589, 17759, 18099,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    0, 54, 65, 159, 164, 266, 292, 295, 382, 396, 467, 468, 530, 748, 749, 754,
    818, 848, 849, 850, 851, 932, 1131, 1144, 1151, 1182, 1284, 1285, 1417,
    1491, 1492, 1617, 1791, 1847, 2015, 2139, 2140, 2368, 2375, 2496, 2502,
    2720, 2892, 3186, 3344, 4216, 4589, 4791, 5044, 5583, 5720, 8299, 8642,
    9428, 9620, 9809, 10495, 10502, 10531, 10540, 10774, 13338, 13534, 13594,
    14722, 14731, 14735, 16243, 16288, 16306, 16505, 16531, 16757, 16842, 16868,
    16869, 17017, 17018, 17019, 2131, 275, 1617, 9387, 17638, 17759, 16926,
    18099,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "remitos/report",
          parametros: ["remitos"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Letras",
              type: "textRange",
            },
            {
              label: filterNames[1].label,
              variable: "Numeros",
              type: "textRange",
            },
            {
              label: filterNames[2].label,
              variable: "PuntoVenta",
              type: "textRange",
            },
            { label: filterNames[3].label, variable: "Fecha", type: "date" },
            {
              label: filterNames[4].label,
              variable: "Liberacion",
              type: "checkboxDate",
            },
            {
              label: filterNames[5].label,
              variable: "Despacho",
              type: "checkboxDate",
            },
            {
              label: filterNames[6].label,
              variable: "CargaRetorno",
              type: "checkboxDate",
            },
            {
              label: filterNames[7].label,
              variable: "Incluir Operaciones Pendientes",
              type: "checkbox",
            },
            {
              label: filterNames[8].label,
              variable: "TipoRemito",
              type: "multipleSelection",
              parametros: ["codPais"],
              urlApi: "remitos/combo/remitos-sin-anulacion",
            },
            {
              label: filterNames[9].label,
              variable: "Mercados",
              type: "multipleSelection",
              parametros: ["codPais"],
              urlApi: "remitos/combo/mercado",
            },
            {
              label: filterNames[10].label,
              variable: "Estados",
              type: "multipleSelection",
              parametros: ["codPais"],
              urlApi: "remitos/combo/estado-remito",
            },
            {
              label: filterNames[11].label,
              variable: "ListarVehiculoConDanos",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "remitos/combo/si-no-ambos",
            },
          ],
        }}
      />
      <Panel header="Remitos">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          <Column field="" header={columnsNames[19].label} sortable filter />
          <Column field="" header={columnsNames[20].label} sortable filter />
          <Column field="" header={columnsNames[21].label} sortable filter />
          <Column field="" header={columnsNames[22].label} sortable filter />
          <Column field="" header={columnsNames[23].label} sortable filter />
          <Column field="" header={columnsNames[24].label} sortable filter />
          <Column field="" header={columnsNames[25].label} sortable filter />
          <Column field="" header={columnsNames[26].label} sortable filter />
          <Column field="" header={columnsNames[27].label} sortable filter />
          <Column field="" header={columnsNames[28].label} sortable filter />
          <Column field="" header={columnsNames[29].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default Remitos;

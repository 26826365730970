import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import React from "react";
import {LanguageProvider} from "@shared/components/language-provider";
import {convertDateObjects} from "@shared/components/utils/convertDateObjects";
const DigitalizedDocuments: React.FC<{ vinData: any }> = ({ vinData }) => {
    const data =  convertDateObjects(vinData?.reporteDocumentos.filas);
    const filterLabelId =[]
    const filterNames = [];
    const filterColumnsId = [0,1491,11849,17926,31,8385,1342,6781,13358,3184,1316,10794]
    const columnsNames = [];
    for (let i = 0; i < filterLabelId.length; i++) {
        filterNames.push({
            id: filterLabelId[i],
            label: LanguageProvider({ id: filterLabelId[i], alt: "Error Filter Labels" })
        });
    }
    for (let i = 0; i < filterColumnsId.length; i++) {
        columnsNames.push({
            id: filterColumnsId[i],
            label: LanguageProvider({ id: filterColumnsId[i] , alt: "Error Columns Labels"})
        });
    }

    return (
        <div className="DigitalizedDocuments">
            <Panel >
                <DataTable size={"small"} showGridlines stripedRows  value={data} paginator scrollHeight="flex" filterDisplay="row" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}" style={{maxWidth:"100%"}} >
                    <Column field="1" sortable filter header="VIN"></Column>
                    <Column field="2" sortable filter header="Nombre Archivo"></Column>
                    <Column field="3" sortable filter header="Codigo Tipo Documento"></Column>
                    <Column field="4" sortable filter header="Descripcion"></Column>
                    <Column field="5" sortable filter header="Fecha Carga"></Column>
                    <Column field="6" sortable filter header="Fecha Despacho"></Column>
                    <Column field="7" sortable filter header="Letra"></Column>
                    <Column field="8" sortable filter header="Punto Venta"></Column>
                    <Column field="9" sortable filter header="Remito"></Column>
                    <Column field="10" sortable filter header="Série"></Column>
                    <Column field="11" sortable filter header="Número CTRC"></Column>
                </DataTable>
            </Panel>
        </div>
    );
};
export default DigitalizedDocuments
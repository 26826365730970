import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import React, {useRef} from "react";
import ExportButtons from "@shared/components/Export";
import {LanguageProvider} from "@shared/components/language-provider";
import {convertDateObjects} from "@shared/components/utils/convertDateObjects";

const MoveHistory: React.FC<{ vinData: any }> = ({ vinData }) => {


    const dt = useRef(null);
    const data =  convertDateObjects(vinData?.reporteHistoricoMovimentos.filas);
    const columns = [
        { field: '1', header: 'Fecha Int (>= a Facturar)' },
        // ... restante das suas colunas ...
    ];

    const filterLabelId =[]
    const filterNames = [];
    const filterColumnsId = [2131,1166,449,921,466,294,266,492,256,4061,1142,1142,1142,1142,1142,3625,1,2205,11370,16571,16572,2892,3625,0]
    const columnsNames = [];
    for (let i = 0; i < filterLabelId.length; i++) {
        filterNames.push({
            id: filterLabelId[i],
            label: LanguageProvider({ id: filterLabelId[i], alt: "Error Filter Labels" })
        });
    }
    for (let i = 0; i < filterColumnsId.length; i++) {
        columnsNames.push({
            id: filterColumnsId[i],
            label: LanguageProvider({ id: filterColumnsId[i] , alt: "Error Columns Labels"})
        });
    }

    return (
        <div className="MoveHistory">
            <Panel>
                <DataTable size={"small"} showGridlines stripedRows 
                    ref={dt}
                    value={data}
                    paginator scrollHeight="flex"
                    filterDisplay="row"
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" style={{maxWidth:"100%"}}
                    footer={<ExportButtons dt={dt} data={data} columns={columnsNames}  screenName={"MoveHistory"}/>}
                >
                    <Column field="1" sortable filter header="Fecha Int (>= a Facturar)"></Column>
                    <Column field="2" sortable filter header="Playas"></Column>
                    <Column field="3" sortable filter header="Ubicaciones"></Column>
                    <Column field="4" sortable filter header="Transaccciones"></Column>
                    <Column field="5" sortable filter header="Remitos"></Column>
                    <Column field="6" sortable filter header="Concesionarios"></Column>
                    <Column field="7" sortable filter header="Dirección de Entrega"></Column>
                    <Column field="8" sortable filter header="CheckPoints"></Column>
                    <Column field="9" sortable filter header="Buque"></Column>
                    <Column field="10" sortable filter header="Referencias"></Column>
                    <Column field="11" sortable filter header="Observaciones"></Column>
                    <Column field="12" sortable filter header="Usuario"></Column>
                    <Column field="13" sortable filter header="Orden"></Column>
                    <Column field="14" sortable filter header="Fecha Sola"></Column>
                    <Column field="20" sortable filter header="Hora Sola"></Column>
                    <Column field="16" sortable filter header="Playa"></Column>
                </DataTable>
            </Panel>
        </div>
    );
};
export default MoveHistory
import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import usePageViews from "../../../hooks/usePageViews";

export function StockTaller() {
  const SCREEN_CODE = "con0042_stock_taller";
  const [stockTallerData, setStockTallerData] = useState(null);

  usePageViews();
  const dt = useRef(null);
  const data = stockTallerData?.filas;

  const filterLabelId = [327, 2183];
  const filterNames = [];
  const filterColumnsId = [16476, 10829, 31, 2183, 1253, 2265, 2890];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 4791, 327, 2183, 16476, 10829, 31, 1253, 2183,
    2265, 2890, 1002,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setStockTallerData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/stock-taller",
          parametros: ["StockTaller"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Taller",
              type: "dropdown",
              parametros: ["descripcion", "codPais"],
              urlApi: "taller-options",
            },
            {
              label: filterNames[1].label,
              variable: "Rubro",
              type: "dropdown",
              parametros: ["descripcion", "codPais"],
              urlApi: "rubros-options",
            },
          ],
        }}
      />
      <Panel header="Stock Taller">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          paginator
          ref={dt}
          value={data}
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default StockTaller;

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import React from "react";
import {LanguageProvider} from "@shared/components/language-provider";
import {convertDateObjects} from "@shared/components/utils/convertDateObjects";



const LocationHistory: React.FC<{ vinData: any }> = ({ vinData }) => {
    const filterLabelId =[]
    const filterNames = [];
    const filterColumnsId = [2892,990,991,992,1197,16578,16579,0,0]
    const columnsNames = [];
    const data =  convertDateObjects(vinData?.reporteEstadiaMovimiento.filas);
    for (let i = 0; i < filterLabelId.length; i++) {
        filterNames.push({
            id: filterLabelId[i],
            label: LanguageProvider({ id: filterLabelId[i], alt: "Error Filter Labels" })
        });
    }
    for (let i = 0; i < filterColumnsId.length; i++) {
        columnsNames.push({
            id: filterColumnsId[i],
            label: LanguageProvider({ id: filterColumnsId[i] , alt: "Error Columns Labels"})
        });
    }

    return (
        <div className="LocationHistory">
            <Panel >
                <DataTable size={"small"} showGridlines stripedRows  value={data} paginator scrollHeight="flex" filterDisplay="row" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}" style={{maxWidth:"100%"}} >
                    <Column field="0" sortable filter header="Playa"></Column>
                    <Column field="1" sortable filter header="Calle"></Column>
                    <Column field="2" sortable filter header="Columna"></Column>
                    <Column field="3" sortable filter header="Nível"></Column>
                    <Column field="4" header="Ingresso a Playas"></Column>
                    <Column field="5" header="Ingresso Ubicacion"></Column>
                    <Column field="6" sortable filter header="Cliente Terceros"></Column>
                </DataTable>
            </Panel>
        </div>
    );
};

export default LocationHistory;
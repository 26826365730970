import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ImpressionHojaRuta() {
  const SCREEN_CODE = "con0046_impresion_hoja_ruta";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [467, 468, 441];
  const filterNames = [];
  const filterColumnsId = [2940, 3129, 3127, 3141, 4285, 4284, 441];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    753, 39, 54, 65, 441, 448, 467, 468, 743, 748, 749, 1131, 2940, 3127, 3129,
    3141, 4284, 4285, 5044, 5558, 10531, 188, 16239, 10043, 10044,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/impresion-hoja-ruta",
          parametros: ["impressionHojaRuta"],
          customFilters: [
            {
              label: filterNames[0].label + filterNames[1].label,
              variable: "Fecha",
              type: "date",
            },
            {
              label: filterNames[2].label,
              variable: "Playas",
              type: "multipleSelection",
              parametros: ["codPais", "codCuenta", "descripcion"],
              urlApi: "subcuentas-options",
            },
          ],
        }}
      />
      <Panel header="Impression Hoja de Ruta">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"ImpresionHojaRuta"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ImpressionHojaRuta;

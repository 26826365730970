import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ConsultaOt() {
  const SCREEN_CODE = "con0061_consulta_de_ot";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [14370, 467, 14368, 14369, 901, 18308, 18309, 1569];
  const filterNames = [];
  const filterColumnsId = [
    14369, 1491, 1569, 14368, 13324, 11833, 32, 11986, 11035, 1669, 396, 13514,
    2139, 18305, 2140, 367, 367, 2892, 6367, 16580, 3410, 18308, 18307,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    32, 54, 65, 448, 467, 468, 653, 901, 1182, 1184, 1491, 1569, 1669, 2502,
    4791, 5044, 5583, 1480, 11035, 11833, 11986, 12642, 13324, 14368, 14369,
    14370, 14562, 18308, 18309, 396, 13514, 2139, 18305, 2140, 367, 2892, 6367,
    16580, 3410, 18308, 18307, 749, 748,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/consulta-ot",
          parametros: ["consultaOt"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "fechaOt",
              type: "checkbox",
            },
            { label: filterNames[1].label, variable: "Fecha", type: "date" },
            {
              label: filterNames[2].label,
              variable: "EstadoOT",
              type: "dropdown",
              parametros: ["codPais", "descripcion"],
              urlApi: "combo/consulta-ot/estado",
            },
            { label: filterNames[3].label, variable: "NumeroOT", type: "text" },
            {
              label: filterNames[4].label,
              variable: "Vin",
              type: "text",
              urlApi: "combo/consulta-ot/vin",
            },
            {
              label: filterNames[5].label,
              variable: "OrigenSolicitud",
              type: "multipleSelection",
              parametros: ["codPais", "codCliente", "descripcion"],
              urlApi: "combo/consulta-ot/origen",
            },
            {
              label: filterNames[6].label,
              variable: "GrupoOperacion",
              type: "dropdown",
              parametros: ["codPais", "codCliente", "descripcion"],
              urlApi: "combo/consulta-ot/grupo-operacion",
              optionAll: true,
            },
            {
              label: filterNames[7].label,
              variable: "Operacion",
              type: "multipleSelection",
              parametros: ["codPais", "codCliente", "descripcion"],
              urlApi: "combo/consulta-ot/operacion",
            },
          ],
        }}
      />
      <Panel header="Consulta Orden Trabajo">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOt;

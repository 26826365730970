import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ConsultaOfertasServicio() {
  const SCREEN_CODE = "con0066_consulta_ofertas_de_servicio";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [3344, 54, 16563, 6256];
  const filterNames = [];
  const filterColumnsId = [
    16563, 54, 54, 6256, 5683, 16817, 9990, 16197, 14791, 16818, 3206, 1669,
    17088, 4724, 16566, 5496, 5490, 7235, 16567, 16590, 10909, 11237, 10683,
    16076, 396, 2139, 2521, 16568, 16591, 924, 14083, 1545, 13706, 5271,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    924, 14083, 1545, 13706, 5271, 16563, 54, 6256, 5683, 16817, 9990, 16197,
    14791, 16818, 3206, 1669, 17088, 4724, 16566, 5496, 5490, 7235, 16567,
    16590, 10909, 11237, 10683, 16076, 396, 2139, 2521, 16568, 16591, 3344,
    16563, 6256, 2621, 2622, 448, 743, 65, 5583, 5044, 54, 1791, 2521, 467, 468,
    1182, 31, 11659,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "/ofertas-servicios/report",
          parametros: ["ofertasServicio"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Estado",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "/ofertas-servicios/combo/estado",
              optionAll: true,
            },
            {
              label: filterNames[1].label,
              variable: "Clientes",
              type: "dropdown",
              urlApi: "/ofertas-servicios/combo/clientes",
              optionAll: true,
              descripcionAddCode: true,
            },
            {
              label: filterNames[2].label,
              variable: "NroOferta",
              type: "text",
            },
            {
              label: filterNames[3].label,
              variable: "Fecha",
              type: "checkboxDate",
            },
          ],
        }}
      />
      <Panel header="Consulta Ofertas Servicio">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOfertasServicio;

export class DropdownValueDTO<T> {
  value: T;
  description: string;

  constructor();
  constructor(value: T, description: string);
  constructor(value?: T, description?: string) {
    this.value = value !== undefined ? value : (null as unknown as T);
    this.description = description !== undefined ? description : "";
  }
}

export class CuentaSecurityFilter {
  account: DropdownValueDTO<number>;
  subAccounts: DropdownValueDTO<number>[];

  constructor();
  constructor(
    account: DropdownValueDTO<number>,
    subAccounts: DropdownValueDTO<number>[]
  );
  constructor(
    account?: DropdownValueDTO<number>,
    subAccounts?: DropdownValueDTO<number>[]
  ) {
    this.account =
      account !== undefined ? account : new DropdownValueDTO<number>();
    this.subAccounts = subAccounts !== undefined ? subAccounts : [];
  }
}

export class ClientSecurityFilter {
  client: DropdownValueDTO<string>;
  accounts: CuentaSecurityFilter[];

  constructor();
  constructor(
    client: DropdownValueDTO<string>,
    accounts: CuentaSecurityFilter[]
  );
  constructor(
    client?: DropdownValueDTO<string>,
    accounts?: CuentaSecurityFilter[]
  ) {
    this.client =
      client !== undefined ? client : new DropdownValueDTO<string>();
    this.accounts = accounts !== undefined ? accounts : [];
  }
}

export class ScreenSecurityFilters {
  country: DropdownValueDTO<number>;
  clients: ClientSecurityFilter[];

  constructor();
  constructor(
    country: DropdownValueDTO<number>,
    clients: ClientSecurityFilter[]
  );
  constructor(
    country?: DropdownValueDTO<number>,
    clients?: ClientSecurityFilter[]
  ) {
    this.country =
      country !== undefined ? country : new DropdownValueDTO<number>();
    this.clients = clients !== undefined ? clients : [];
  }
}

import React, { useRef } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import usePageViews from "../../../hooks/usePageViews";

export function ConsultaOperacionesPendientes() {
  const SCREEN_CODE = "con0058_consulta_operaciones_pendientes";
  usePageViews();
  const dt = useRef(null);
  const data = [];
  const columns = [];
  const filterLabelId = [18308, 18309, 1569, 901, 321, 19178];
  const filterNames = [];
  const filterColumnsId = [
    54, 2892, 1491, 1569, 990, 991, 396, 13514, 2139, 18305, 2140, 10034, 367,
    12251, 3410, 18202, 11507, 17131, 1492, 3344, 10871, 2941, 10868, 10904,
    18306, 18307, 15739, 19690, 5366, 14370, 16245, 54, 2892, 1491, 19179, 1569,
    5788, 16416, 8380, 990, 991, 396, 13514, 2139, 18305, 2140, 10034, 367,
    12251, 3410, 18202, 11507, 17131, 1492, 3344, 10871, 2941, 10868, 10904,
    18306, 18307, 15739, 19690, 5366, 2521, 14370,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 13, 54, 65, 396, 448, 466, 467, 468, 653, 748, 749, 900, 901, 990, 991,
    1182, 1184, 1491, 1569, 1791, 2139, 15739, 2140, 2892, 3750, 4589, 4791,
    5044, 5271, 5583, 10034, 13706, 14288, 14370, 16501, 13514, 18305, 367,
    12251, 3410, 18202, 11507, 17131, 1492, 3344, 10871, 2941, 10868, 10904,
    18306, 18307, 748, 749, 321, 18308, 18309, 10531, 5366, 19178, 16245, 10774,
    16416, 5788, 19179, 8380, 2502, 2521, 19690,
  ];

  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={(e) => console.log(e)}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/operaciones-pendientes",
          parametros: ["consultaOperacionPendiente"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Origen Solicitud",
              type: "multipleSelection",
              parametros: ["codPais"],
              urlApi: "combo/origen-solicitud",
            },
            {
              label: filterNames[1].label,
              variable: "Grupo Operacion",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "combo/grupo-operaciones",
              optionAll: true,
              descripcionAddCode: true,
            },
            {
              label: filterNames[2].label,
              variable: "Operacion",
              type: "multipleSelection",
              parametros: ["codPais", "grupoOperacion"],
              urlApi: "combo/operaciones-options",
              optionAll: true,
              descripcionAddCode: true,
            },
            {
              label: filterNames[3].label,
              variable: "Ingrese el VIN",
              type: "text",
            },
            {
              label: filterNames[4].label,
              variable: "Remito de Despacho",
              type: "checkbox",
            },
            {
              label: filterNames[5].label,
              variable: "Archivo Registracion WEB",
              type: "checkbox",
            },
          ],
        }}
      />
      <Panel header="Consulta Operaciones Pendientes">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          <Column field="" header={columnsNames[19].label} sortable filter />
          <Column field="" header={columnsNames[20].label} sortable filter />
          <Column field="" header={columnsNames[21].label} sortable filter />
          <Column field="" header={columnsNames[22].label} sortable filter />
          <Column field="" header={columnsNames[23].label} sortable filter />
          <Column field="" header={columnsNames[24].label} sortable filter />
          <Column field="" header={columnsNames[25].label} sortable filter />
          <Column field="" header={columnsNames[26].label} sortable filter />
          <Column field="" header={columnsNames[27].label} sortable filter />
          <Column field="" header={columnsNames[28].label} sortable filter />
          <Column field="" header={columnsNames[29].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOperacionesPendientes;

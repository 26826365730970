import "./AntiguedadStock.scss";

import React, { useEffect, useRef, useState } from "react";
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
  TabViewTabChangeEvent,
} from "primereact/tabview";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function AntiguedadStock() {
  const SCREEN_CODE = "con0023_antiguedad_stock";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte01, setReporte01] = useState(null);
  const [reporte02, setReporte02] = useState(null);
  const [reporte03, setReporte03] = useState(null);
  const [reporte04, setReporte04] = useState(null);
  const data01 = convertDateObjects(reporte01?.filas);
  const data02 = convertDateObjects(reporte02?.filas);
  const data03 = convertDateObjects(reporte03?.filas);
  const data04 = convertDateObjects(reporte04?.filas);
  const [tabViewActiveIntex, setTabViewActiveIntex] = useState(0);
  const filterLabelId = [4880, 2496, 10774, 369];
  const filterNames = [];
  const filterColumnsId = [
    4245, 5715, 4891, 5720, 1491, 2892, 10774, 2496, 396,
  ];
  const columnsNames = [];

  const fromLabel = LanguageProvider({ id: "2621", alt: "Desde" });
  const untilLabel = LanguageProvider({ id: "2622", alt: "Hasta" });

  // const mensagens = [29,42,54,65,369,396,748,749,1144,1198,1230,1234,1263,1491,1791,2139,2140,2496,2502,
  // 				2724,2892,3277,4245,4791,4880,4891,5044,5240,5583,5715,5720,10531,10774];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [loading, setLoading] = useState(false);
  const [mercados, setMercados] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [destinos, setDestinos] = useState([]);

  const [filter, setFilter] = useState({
    interval01Start: 0,
    interval01End: 30,
    interval02Start: 31,
    interval02End: 60,
    interval03Start: 61,
    interval03End: 90,
    interval04Start: 91,
    interval04End: 120,
    mercados: [],
    origenes: [],
    destinos: [],
  });

  async function searchMercados(event: any) {
    await loadMercados(event.query);
  }
  async function loadMercados(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/antiguedad-stock/mercados",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setMercados(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMercados(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchOrigenes(event: any) {
    await loadOrigenes(event.query);
  }
  async function loadOrigenes(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/antiguedad-stock/origenes",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setOrigenes(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setOrigenes(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchDestinos(event: any) {
    await loadDestinos(event.query);
  }
  async function loadDestinos(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/antiguedad-stock/destinos",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setDestinos(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setDestinos(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "antiguedad-stock/report",
        {
          codPais: securityFilters?.securityValues?.country?.value || null,
          codCliente: securityFilters?.securityValues?.client?.value || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.value || null,
          codSubcuenta:
            securityFilters?.securityValues?.subaccount?.value || null,
          mercado: filter?.mercados?.map((x) => x.id) || [],
          destinos: filter?.destinos?.map((x) => x.id) || [],
          origens: filter?.origenes?.map((x) => x.id) || [],
          intervalo: [
            [filter.interval01Start, filter.interval01End],
            [filter.interval02Start, filter.interval02End],
            [filter.interval03Start, filter.interval03End],
            [filter.interval04Start, filter.interval04End],
          ],
        }
      );

      if (status === 200) {
        setReporte01(data[0]);
        setReporte02(data[1]);
        setReporte03(data[2]);
        setReporte04(data[3]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadOrigenes();
    loadMercados();
    loadDestinos();
  }, [securityFilters.securityValues.country]);

  return (
    <div className="antiguedad-stock">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="filter-options collumn">
            <div className="filter-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                </label>
                <AutoComplete
                  value={filter.mercados}
                  suggestions={mercados}
                  completeMethod={searchMercados}
                  onChange={(e) => setFilter({ ...filter, mercados: e.value })}
                  field="label"
                  dropdown
                  disabled={!securityFilters.securityValues.country}
                  multiple
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2496"} alt="Origen" />
                </label>
                <AutoComplete
                  value={filter.origenes}
                  suggestions={origenes}
                  completeMethod={searchOrigenes}
                  onChange={(e) => setFilter({ ...filter, origenes: e.value })}
                  field="label"
                  dropdown
                  disabled={!securityFilters.securityValues.country}
                  multiple
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"369"} alt="Destinos" />
                </label>
                <AutoComplete
                  value={filter.destinos}
                  suggestions={destinos}
                  completeMethod={searchDestinos}
                  onChange={(e) => setFilter({ ...filter, destinos: e.value })}
                  field="label"
                  dropdown
                  disabled={!securityFilters.securityValues.country}
                  multiple
                />
              </div>
            </div>
            <div className="filter-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Intervalo 1" />
                </label>
                <InputNumber
                  placeholder={fromLabel}
                  min={0}
                  value={filter.interval01Start}
                  onChange={(e) =>
                    setFilter({ ...filter, interval01Start: e.value })
                  }
                />
                <InputNumber
                  placeholder={untilLabel}
                  value={filter.interval01End}
                  onChange={(e) =>
                    setFilter({ ...filter, interval01End: e.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Intervalo 2" />
                </label>
                <InputNumber
                  placeholder={fromLabel}
                  min={0}
                  value={filter.interval02Start}
                  onChange={(e) =>
                    setFilter({ ...filter, interval02Start: e.value })
                  }
                />
                <InputNumber
                  placeholder={untilLabel}
                  value={filter.interval02End}
                  onChange={(e) =>
                    setFilter({ ...filter, interval02End: e.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Intervalo 3" />
                </label>
                <InputNumber
                  placeholder={fromLabel}
                  min={0}
                  value={filter.interval03Start}
                  onChange={(e) =>
                    setFilter({ ...filter, interval03Start: e.value })
                  }
                />
                <InputNumber
                  placeholder={untilLabel}
                  value={filter.interval03End}
                  onChange={(e) =>
                    setFilter({ ...filter, interval03End: e.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Intervalo 4" />
                </label>
                <InputNumber
                  placeholder={fromLabel}
                  min={0}
                  value={filter.interval04Start}
                  onChange={(e) =>
                    setFilter({ ...filter, interval04Start: e.value })
                  }
                />
                <InputNumber
                  placeholder={untilLabel}
                  value={filter.interval04End}
                  onChange={(e) =>
                    setFilter({ ...filter, interval04End: e.value })
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="281" alt="Antigüedad de Stock" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={
                    tabViewActiveIntex === 0
                      ? data01
                      : tabViewActiveIntex === 1
                        ? data02
                        : tabViewActiveIntex === 2
                          ? data03
                          : data04
                  }
                  columns={columnsNames}
                  screenName={"AntigüedadStock"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <TabView
          className="tab-view"
          activeIndex={tabViewActiveIntex}
          onTabChange={(event: TabViewTabChangeEvent) =>
            setTabViewActiveIntex(event.index)
          }
        >
          <TabPanel
            headerTemplate={(options: TabPanelHeaderTemplateOptions) => {
              return (
                <div className={options.className} onClick={options.onClick}>
                  <span className={options.titleClassName}>
                    <LanguageProvider id="4880" alt="Intervalo" />{" "}
                    {filter.interval01Start} ~ {filter.interval01End}{" "}
                    <LanguageProvider id="3277" alt={"Días"} />
                  </span>
                </div>
              );
            }}
          >
            <div className="tab-content">
              <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={data02}
                paginator
                scrollHeight="flex"
                filterDisplay="row"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
              >
                <Column
                  field="0"
                  header={columnsNames[0].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[1].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[2].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[3].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[4].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[5].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[6].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[7].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[8].label}
                  sortable
                  filter
                />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel
            headerTemplate={(options: TabPanelHeaderTemplateOptions) => {
              return (
                <div className={options.className} onClick={options.onClick}>
                  <span className={options.titleClassName}>
                    <LanguageProvider id="4880" alt="Intervalo" />{" "}
                    {filter.interval02Start} ~ {filter.interval02End}{" "}
                    <LanguageProvider id="3277" alt={"Días"} />
                  </span>
                </div>
              );
            }}
          >
            <div className="tab-content">
              <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={data03}
                paginator
                scrollHeight="flex"
                filterDisplay="row"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
              >
                <Column
                  field="0"
                  header={columnsNames[0].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[1].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[2].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[3].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[4].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[5].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[6].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[7].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[8].label}
                  sortable
                  filter
                />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel
            headerTemplate={(options: TabPanelHeaderTemplateOptions) => {
              return (
                <div className={options.className} onClick={options.onClick}>
                  <span className={options.titleClassName}>
                    <LanguageProvider id="4880" alt="Intervalo" />{" "}
                    {filter.interval03Start} ~ {filter.interval03End}{" "}
                    <LanguageProvider id="3277" alt={"Días"} />
                  </span>
                </div>
              );
            }}
          >
            <div className="tab-content">
              <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={data04}
                paginator
                scrollHeight="flex"
                filterDisplay="row"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
              >
                <Column
                  field="0"
                  header={columnsNames[0].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[1].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[2].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[3].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[4].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[5].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[6].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[7].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[8].label}
                  sortable
                  filter
                />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel
            headerTemplate={(options: TabPanelHeaderTemplateOptions) => {
              return (
                <div className={options.className} onClick={options.onClick}>
                  <span className={options.titleClassName}>
                    <LanguageProvider id="4880" alt="Intervalo" />{" "}
                    {filter.interval04Start} ~ {filter.interval04End}{" "}
                    <LanguageProvider id="3277" alt={"Días"} />
                  </span>
                </div>
              );
            }}
          >
            <div className="tab-content">
              <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={data04}
                paginator
                scrollHeight="flex"
                filterDisplay="row"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
              >
                <Column
                  field="0"
                  header={columnsNames[0].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[1].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[2].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[3].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[4].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[5].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[6].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[7].label}
                  sortable
                  filter
                />
                <Column
                  field=""
                  header={columnsNames[8].label}
                  sortable
                  filter
                />
              </DataTable>
            </div>
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
export default AntiguedadStock;

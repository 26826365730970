import React, { useEffect, useState } from 'react';
import settings from '../settings.json';
import './scss/footer.scss';
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
    const { ApplicationName, Version, Environment } = settings;
    const location = useLocation();
    const [environment, setEnvironment] = useState<string>(location.pathname);
    const [screenCode, setScreenCode] = useState<string | null>(localStorage.getItem('pageCode'));

    useEffect(() => {
        setEnvironment(location.pathname);
        setScreenCode(localStorage.getItem('pageCode'));
    }, [location]);

    return (
        <footer>
            <div>
                {ApplicationName} : {Version}: {Environment}
            </div>
            <div>
                Current environment: {environment}
            </div>
            <div>
                Screen Code: {screenCode}
            </div>
        </footer>
    );
};

export default Footer;
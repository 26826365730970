import { Route, Routes } from "react-router-dom";

import AdministracionNotasFiscales from "@pages/functions/administracion-notas-fiscales/AdministracionNotasFiscales";
import AdministracionRemitosAConcesionarios from "@pages/functions/administracion-remitos-a-concesionarios/AdministracionRemitosAConcesionarios";
import AntiguedadStock from "@pages/queries/antiguedad-stock/AntiguedadStock";
import CargaDanos from "@pages/functions/carga-danos/CargaDanos";
import { CargaOperacionesForzadas } from "@pages/functions/CargaOpForzadas/Carga-operaciones-forzadas";
import ComprasTaller from "@pages/queries/compras-taller/ComprasTaller";
import ConsultaBloqueos from "@pages/queries/consulta-bloqueos/ConsultaBloqueos";
import ConsultaGeneralTransacciones from "@pages/queries/consulta-general-de-transacciones/ConsultaGeneralTransacciones";
import ConsultaOfertasServicio from "@pages/queries/consulta-ofertas-servicio/ConsultaOfertasServicio";
import ConsultaOperacionesCanceladasWeb from "@pages/queries/consulta-operaciones-canceladas-web/ConsultaOperacionesCanceladasWeb";
import ConsultaOperacionesPendientes from "@pages/queries/consulta-operaciones-pendientes/ConsultaOperacionesPendientes";
import ConsultaOsTerceros from "@pages/queries/consulta-os-terceros/ConsultaOsTerceros";
import ConsultaOt from "@pages/queries/consulta-ot/ConsultaOt";
import ConsultaTranslado from "@pages/queries/consulta-translado/ConsultaTranslado";
import { ConsultaVin } from "@pages/queries/consulta-vin/consulta-vin";
import ConsultaVinesPreparar from "@pages/queries/consulta-vines-preparar/ConsultaVinesPreparar";
import DaniosCheckpoint from "@pages/queries/danio-x-checkpoint/DaniosCheckpoint";
import DespachoMercadoNacional from "@pages/functions/despacho-mercado-nacional/DespachoMercadoNacional";
import Despachos from "@pages/queries/despachos/despacho";
import DisponibilidadImportacion from "@pages/functions/disponibilidad-importacion/DisponibilidadImportacion";
import DocumentacionTransportes from "@pages/queries/documentacio-de-transportes/DocumentacionTransportes";
import EgresoTaller from "@pages/functions/egreso-taller/EgresoTaller";
import { FilterTest } from "@pages/filter-test/filter-test";
import GeneracionOt from "@pages/functions/generacion-ot/GeneracionOt";
import HistoriaVin from "@pages/queries/historia-vin/HistoriaVin";
import HistoricoDanos from "@pages/queries/historico-danios/HistoricoDanos";
import Home from "@pages/home/home";
import ImpressionHojaRuta from "@pages/queries/impression-hoja-ruta/ImpressionHojaRuta";
import IngresoEcom from "@pages/functions/ingreso-ecom/ingreso-ecom";
import IngresoTallerManual from "@pages/functions/ingreso-taller-manual/IngresoTallerManual";
import IngressoPlaya from "@pages/queries/ingresso-playa/IngressoPlaya";
import IngressoTaller from "@pages/queries/ingresso-taller/IngressoTaller";
import LiberacionAduana from "@pages/functions/liberacion-aduana/LiberacionAduana";
import LiberacionDespachos from "@pages/queries/liberacion-despachos/LiberacionDespachos";
import LiberadosDespachados from "@pages/queries/liberados-despachados/LiberadosDespachados";
import ListadoArmadoViajes from "@pages/queries/listado-armado-viajes/ListadoArmadoViajes";
import ListadoColectas from "@pages/queries/listado-colectas/ListadoColectas";
import ListadoHojaRuta from "@pages/queries/listado-hoja-ruta/listado-hoja-ruta";
import ListadoOperacionesRealizadas from "@pages/queries/listado-operaciones-realizadas/ListadoOperacionesRealizadas";
import Login from "@pages/admin/authentication/login";
import LoginLayout from "@shared/login-layout";
import MainLayout from "@shared/main-layout";
import MentenerHojasRuta from "@pages/functions/mantener-hojas-ruta/mantener-hojas-ruta";
import ModificacionVin from "@pages/functions/modificacion-vin/ModificacionVin";
import MovimentoStock from "@pages/queries/movimento-stock/MovimentoStock";
import NotFound from "./shared/not-found";
import OrdenReparacion from "@pages/functions/orden-reparacion/OrdenReparacion";
import PedidosConfirmadosJd from "@pages/queries/pedidos-confirmados-jd/PedidosConfirmadosJd";
import ProtectedRoutes from "./protected-routes";
import RecepcionPlaya from "@pages/functions/recepcion-playa/RecepcionPlaya";
import RegistracionOperacionAutomatica from "@pages/functions/registracion-operacion-automatica/RegistracionOperacionAutomatica";
import RegistracionOperacionPorOt from "@pages/functions/registracion-operacion-por-ot/RegistracionOperacionPorOt";
import RemitoElectronico from "@pages/functions/remito-electronico/RemitoElectronico";
import Remitos from "@pages/queries/remitos/Remitos";
import Stock from "@pages/queries/stock/stock";
import StockTaller from "@pages/queries/stock-taller/StockTaller";
import ThemeLayout from "@shared/theme-layout";
import TiemposCentroPerdidos from "@pages/queries/transit-time-pedidos-playa/TiemposCentroPerdidos";
import TiemposEntrega from "@pages/queries/tiempo-de-entrega/TiemposEntrega";
import TranferenciaPlaya from "@pages/functions/tranferencia-playa/TranferenciaPlaya";
import TransaccionesUsuarios from "@pages/queries/transacciones-usuario/TransaccionesUsuarios";
import TransfOrd from "@pages/queries/transf-ord/TransfOrd";
import TransferenciaUbicacion from "@pages/functions/transferencia-ubicacion/TransferenciaUbicacion";
import ValeRepuestos from "@pages/queries/vale-repuestos/ValeRepuestos";
import VehiculosPendientesTaller from "@pages/queries/vehiculos-pendientes-taller/VehiculosPendientesTaller";
import VehiculosTaller from "@pages/queries/vehiculos-en-taller/VehiculosTaller";
import PedidosPlaya from "@pages/functions/pedidos-playa/PedidosPlaya";
import LlegadaBuqueCamion from "@pages/functions/llegada-buque-camion/LlegadaBuqueCamion";
import DespachoMercadoExportacion from "@pages/functions/despacho-mercado-exportacion/DespachoMercadoExportacion";
import CargaDeRetornos from "@pages/functions/carga-de-retornos/CargaDeRetornos";
import DespachoTerceros from "@pages/functions/despacho-terceros/DespachoTerceros";
import Colecta from "@pages/functions/colecta/Colecta";
import CargaManualRemitos from "@pages/functions/carga-manual-remitos/CargaManualRemitos";
import MantenerValesDeRepuestos from "@pages/functions/mantener-vales-de-repuestos/MantenerValesDeRepuestos";
import ReimpresionEtiquetas from "@pages/functions/reimpresion-etiquetas/ReimpresionEtiquetas";
import ImpresionEtiquetas from "@pages/functions/impresion-etiquetas/ImpresionEtiquetas";
import ImpresionChecklist from "@pages/functions/impresion-checklist/ImpresionChecklist";
import GestionPreparacion from "@pages/functions/gestion-preparacion/GestionPreparacion";
import GenPicking from "@pages/functions/gen-picking/GenPicking";
import BloqueoUbicaciones from "@pages/functions/bloqueo-ubicaciones/BloqueoUbicaciones";

const NavProvider = () => {
  return (
    <Routes>
      <Route element={<ThemeLayout />}>
        <Route path="auth" element={<LoginLayout />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<ProtectedRoutes />}>
          <Route element={<MainLayout />}>
            <Route path="home" element={<Home />} />
            <Route path="filter-test" element={<FilterTest />} />
            <Route path="consulta-vin" element={<ConsultaVin />} />
            <Route path="stock" element={<Stock />} />
            <Route
              path="listado-de-hojas-de-rutas"
              element={<ListadoHojaRuta />}
            />
            <Route
              path="consulta-operaciones-pendientes"
              element={<ConsultaOperacionesPendientes />}
            />
            <Route path="ingresso-playa" element={<IngressoPlaya />} />
            <Route
              path="impression-hoja-ruta"
              element={<ImpressionHojaRuta />}
            />
            <Route path="remitos" element={<Remitos />} />
            <Route path="despachos" element={<Despachos />} />
            <Route path="consulta-ot" element={<ConsultaOt />} />
            <Route
              path="listado-operaciones-realizadas"
              element={<ListadoOperacionesRealizadas />}
            />
            <Route path="historico-danos" element={<HistoricoDanos />} />
            <Route path="listado-colectas" element={<ListadoColectas />} />
            <Route path="stock-taller" element={<StockTaller />} />
            {/* <Route path="egreso-taller" element={<EgresoTaller />} /> */}
            <Route
              path="transacciones-usuarios"
              element={<TransaccionesUsuarios />}
            />
            <Route
              path="listado-armado-viajes"
              element={<ListadoArmadoViajes />}
            />
            <Route path="movimento-stock" element={<MovimentoStock />} />
            <Route path="consulta-bloqueos" element={<ConsultaBloqueos />} />
            <Route
              path="liberados-despachados"
              element={<LiberadosDespachados />}
            />
            <Route path="transf-ord" element={<TransfOrd />} />
            <Route
              path="vehiculo-pendientes-taller"
              element={<VehiculosPendientesTaller />}
            />
            <Route path="historia-vin" element={<HistoriaVin />} />
            <Route path="compras-taller" element={<ComprasTaller />} />
            <Route path="vale-repuestos" element={<ValeRepuestos />} />
            <Route path="antiguedad-stock" element={<AntiguedadStock />} />
            <Route path="danios-checkpoint" element={<DaniosCheckpoint />} />
            <Route path="tiempos-entrega" element={<TiemposEntrega />} />
            <Route path="vehiculos-taller" element={<VehiculosTaller />} />
            <Route path="ingresso-taller" element={<IngressoTaller />} />
            <Route
              path="liberacion-despacho"
              element={<LiberacionDespachos />}
            />
            <Route
              path="documentacion-transportes"
              element={<DocumentacionTransportes />}
            />
            <Route
              path="general-transacciones"
              element={<ConsultaGeneralTransacciones />}
            />
            <Route path="vines-preparar" element={<ConsultaVinesPreparar />} />
            <Route path="translados" element={<ConsultaTranslado />} />
            <Route
              path="ofertas-servicio"
              element={<ConsultaOfertasServicio />}
            />
            <Route
              path="operaciones-canceladas-web"
              element={<ConsultaOperacionesCanceladasWeb />}
            />
            <Route
              path="transit-time-pedidos-playa"
              element={<TiemposCentroPerdidos />}
            />
            <Route
              path="consulta-os-terceros"
              element={<ConsultaOsTerceros />}
            />
            <Route
              path="pedidos-confirmados-jd"
              element={<PedidosConfirmadosJd />}
            />
            {/* <Route path="mantener-hojas-ruta" element={<MentenerHojasRuta />} /> */}
            {/* <Route
              path="administracion-remitos-a-concesionarios"
              element={<AdministracionRemitosAConcesionarios />}
            /> */}
            {/* <Route
              path="administracion-notas-fiscales"
              element={<AdministracionNotasFiscales />}
            /> */}
            {/* <Route
              path="despacho-mercado-nacional"
              element={<DespachoMercadoNacional />}
            />
            <Route path="carga-danos" element={<CargaDanos />} />
            <Route path="transferencia-playa" element={<TranferenciaPlaya />} />
            <Route path="recepcion-de-playa" element={<RecepcionPlaya />} />
            <Route path="generacion-ot" element={<GeneracionOt />} />
            <Route
              path="ingreso-taller-manual"
              element={<IngresoTallerManual />}
            />
            <Route path="orden-reparacion" element={<OrdenReparacion />} />
            <Route
              path="registracion-operacion-por-ot"
              element={<RegistracionOperacionPorOt />}
            /> */}
            {/* <Route
              path="registracion-operacion-automatica"
              element={<RegistracionOperacionAutomatica />}
            /> */}
            {/* <Route path="ingreso-ecom" element={<IngresoEcom />} /> */}
          
            <Route
              path="/carga-operaciones-forzadas"
              element={<CargaOperacionesForzadas />}
            />
            {/* <Route path="modificacion-vin" element={<ModificacionVin />} /> */}
            {/* <Route
              path="transferencia-ubicacion"
              element={<TransferenciaUbicacion />}
            /> */}
            {/* <Route path="remito-electronico" element={<RemitoElectronico />} /> */}
            {/* <Route
              path="disponibilidad-importacion"
              element={<DisponibilidadImportacion />}
            /> */}
            {/* <Route path="liberacion-aduana" element={<LiberacionAduana />} /> */}
            {/* <Route path="pedidos-playa" element={<PedidosPlaya />} /> */}
            {/* <Route
              path="llegada-buque-camion"
              element={<LlegadaBuqueCamion />}
            /> */}
            {/* <Route
              path="despacho-mercado-exportacion"
              element={<DespachoMercadoExportacion />}
            /> */}
            {/* <Route path="carga-de-retornos" element={<CargaDeRetornos />} /> */}
            {/* <Route path="despacho-terceros" element={<DespachoTerceros />} /> */}
            {/* <Route path="colecta" element={<Colecta />} /> */}
            {/* <Route
              path="carga-manual-remitos"
              element={<CargaManualRemitos />}
            /> */}
            {/* <Route
              path="mantener-vales-de-repuestos"
              element={<MantenerValesDeRepuestos />}
            /> */}
            {/* <Route
              path="reimpresion-etiquetas"
              element={<ReimpresionEtiquetas />}
            /> */}
            {/* <Route
              path="impresion-etiquetas"
              element={<ImpresionEtiquetas />}
            /> */}
            {/* <Route
              path="impresion-checklist"
              element={<ImpresionChecklist />}
            /> */}
            {/* <Route
              path="gestion-preparacion"
              element={<GestionPreparacion />}
            /> */}
            {/* <Route path="gen-picking" element={<GenPicking />} /> */}
            {/* <Route
              path="bloqueo-ubicaciones"
              element={<BloqueoUbicaciones />}
            /> */}
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default NavProvider;

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ConsultaBloqueos() {
  const SCREEN_CODE = "con0053_bloqueos";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    11335, 11334, 11336, 396, 2139, 2140, 1491, 10681, 11085, 11086, 11072,
    16907,
  ];
  const filterNames = [];
  const filterColumnsId = [
    11072, 10766, 3410, 10747, 2368, 10794, 11126, 3344, 4589, 11127, 1491,
    1569, 11072, 1143, 1669, 11097, 2368, 4589, 1491, 1569, 11072, 1143, 1669,
    11097, 4589,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 396, 467, 468, 1143, 1144, 1182, 1184, 1491, 1569, 1669, 1791, 2139,
    2140, 2368, 2502, 2785, 3344, 3410, 4102, 4589, 4791, 5044, 5583, 10681,
    10747, 10766, 10794, 11072, 11085, 11086, 11097, 11125, 11126, 11127, 11129,
    11334, 11335, 11336, 16907,
  ];

  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    stock: "",
    marca: null,
    modelo: "",
    color: null,
    vin: "",
    paisesDestino: null,
    anoFabricacion: new Date(),
    anoModelo: new Date(),
    codigoBloqueo: null,
    fechaMovimiento: [new Date(), new Date()],
    tipoConsulta: "bloqAct",
  });

  const [loading, setLoading] = useState(false);

  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [colores, setColores] = useState([]);
  const [vin, setVin] = useState([]);
  const [paisDestino, setPaisDestino] = useState([]);
  const [codigosBloqueo, setCodigosBloqueo] = useState([]);

  async function searchMarcas(event: any) {
    await loadMarcas(event.query);
  }
  async function loadMarcas(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "consulta-bloqueo/marca",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          let marcas = data.filter((x: any) =>
            x.label.toLowerCase().includes(query.toLowerCase())
          );
          setMarcas(marcas);
        } else {
          let marcas = data;
          setMarcas(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchModelos(value: string) {
    await loadModelos(value);
  }
  async function loadModelos(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "consulta-bloqueo/modelos",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
            codModelo: query,
          },
        }
      );

      if (status === 200) {
        if (query) {
          setModelos(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setModelos(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchColores(event: any) {
    await loadColores(event.query);
  }
  async function loadColores(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "consulta-bloqueo/colores",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setColores(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setColores(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchPaisDestino(event: any) {
    await loadPaisDestino(event.query);
  }
  async function loadPaisDestino(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "consulta-bloqueo/pais-destino",
        {
          params: {
            description: query,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setPaisDestino(
            data.filter((x: any) =>
              x.descripcion.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setPaisDestino(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchCodigoBloqueo(event: any) {
    await loadCodigoBloqueo(event.query);
  }
  async function loadCodigoBloqueo(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "consulta-bloqueo/tipo-bloqueos",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setCodigosBloqueo(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setCodigosBloqueo(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchVins(value: string) {
    await loadVins(value);
  }
  async function loadVins(query = "") {
    try {
      if (filter.marca.length > 0) {
        const selectedBrand = filter.marca[0];
        const { status, data } = await sipcoAxiosService.get(
          "consulta-bloqueo/vins",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codCuenta: securityFilters.securityValues.account.value,
              codMarca: selectedBrand.id,
              codVin: query,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setVin(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setVin(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "consulta-bloqueo/report",
        {
          codPais: securityFilters?.securityValues?.country?.value || null,
          codCliente: securityFilters?.securityValues?.client?.value || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.value || null,
          codSubcuenta:
            securityFilters?.securityValues?.subaccount?.value || null,
          marca: filter?.marca || "",
          modelo: filter?.modelo || "",
          color: filter?.color?.id || "",
          vin: filter?.vin || "",
          paisDestino: filter?.paisesDestino?.id || null,
          anoFabricacion: filter?.anoFabricacion.getFullYear(),
          anoModelo: filter?.anoModelo.getFullYear(),
          tipoBloqueo: filter?.codigoBloqueo?.id || null,
          rangeFecha: filter?.fechaMovimiento || [],
          bloqAct: filter?.tipoConsulta === "bloqAct",
          bloqHistVin: filter?.tipoConsulta === "bloqHistVin",
          bloqHist: filter?.tipoConsulta === "bloqHist",
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  console.log("Security Filters =>", securityFilters.securityValues);

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="RadioButtonFilter">
              <RadioButton
                checked={filter.tipoConsulta === "bloqAct"}
                onChange={(e) => {
                  setFilter({ ...filter, tipoConsulta: "bloqAct" });
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[1].label}
                  alt="Bloqueos Actuales"
                />
              </label>
              <RadioButton
                checked={filter.tipoConsulta === "bloqHistVin"}
                onChange={(e) => {
                  setFilter({ ...filter, tipoConsulta: "bloqHistVin" });
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[2].label}
                  alt="Histórico de Bloqueos de Vines en Stock"
                />
              </label>
              <RadioButton
                checked={filter.tipoConsulta === "bloqHist"}
                onChange={(e) => {
                  setFilter({ ...filter, tipoConsulta: "bloqHist" });
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[3].label}
                  alt="Histórico de Bloqueos"
                />
              </label>
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[3].id} alt="Marca" />
              </label>
              <AutoComplete
                value={filter.marca}
                suggestions={marcas}
                completeMethod={searchMarcas}
                onChange={(e) => setFilter({ ...filter, marca: e.value })}
                field="label"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[4].id} alt="Modelo" />
              </label>
              <InputText
                value={filter.modelo}
                onChange={(e) =>
                  setFilter({ ...filter, modelo: e.target.value })
                }
                onBlur={(e) => searchModelos(e.target.value)}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[5].id} alt="Colores" />
              </label>
              <AutoComplete
                value={filter.color}
                suggestions={colores}
                completeMethod={searchColores}
                onChange={(e) => setFilter({ ...filter, color: e.value })}
                field="descripcionFull"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[6].id} alt="Ingrese el VIN" />
              </label>
              <InputText
                value={filter.vin}
                onChange={(e) => setFilter({ ...filter, vin: e.target.value })}
                onBlur={(e) => searchVins(e.target.value)}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[7].id} alt="País Destino" />
              </label>
              <AutoComplete
                value={filter.paisesDestino}
                suggestions={paisDestino}
                completeMethod={searchPaisDestino}
                onChange={(e) =>
                  setFilter({ ...filter, paisesDestino: e.value })
                }
                field="descripcion"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[8].id}
                  alt="Año Fabricacion"
                />
              </label>
              <Calendar
                value={filter.anoFabricacion}
                selectionMode="single"
                onChange={(e) => {
                  setFilter({ ...filter, anoFabricacion: e.value });
                }}
                dateFormat="yy"
                view="year"
                hideOnRangeSelection
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[9].id} alt="Año Modelo" />
              </label>
              <Calendar
                value={filter.anoModelo}
                selectionMode="single"
                onChange={(e) => {
                  setFilter({ ...filter, anoModelo: e.value });
                }}
                dateFormat="yy"
                view="year"
                hideOnRangeSelection
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[10].id}
                  alt="Código Bloqueo"
                />
              </label>
              <AutoComplete
                value={filter.codigoBloqueo}
                suggestions={codigosBloqueo}
                completeMethod={searchCodigoBloqueo}
                onChange={(e) =>
                  setFilter({ ...filter, codigoBloqueo: e.value })
                }
                field="label"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[11].id}
                  alt="Fechas Movimiento"
                />
              </label>
              <Calendar
                value={filter.fechaMovimiento}
                selectionMode="range"
                onChange={(e) => {
                  setFilter({ ...filter, fechaMovimiento: e.value });
                }}
                dateFormat="dd/mm/yy"
                hideOnRangeSelection
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Consulta Bloqueos</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta-Bloqueo"}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          <Column field="" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaBloqueos;

import React, { createContext, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  function login(user) {
    localStorage.setItem("loginData", JSON.stringify(user));
    setUser(user);
  }

  function logout() {
    localStorage.removeItem("loginData");
    navigate("/auth/login");
  }

  useEffect(() => {
    if (!user) {
      const storedUser = JSON.parse(localStorage.getItem("loginData"));
      setUser(storedUser);
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default useAuth;

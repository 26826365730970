import React, { useRef } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import usePageViews from "../../../hooks/usePageViews";

export function ListadoHojaRuta() {
  const SCREEN_CODE = "con0047_listado_hoja_ruta";
  usePageViews();
  const dt = useRef(null);
  const data = [];
  const columns = [];
  const filterLabelId = [16453, 54, 504, 11787, 3344];
  const filterNames = [];
  const filterColumnsId = [
    924, 16453, 3344, 10136, 24859, 23097, 4285, 10902, 4655, 11787, 16454,
    16455, 3206, 24400, 16456, 54, 1491, 4724, 3184, 22927, 22928, 2941, 25012,
    1342, 15862, 25079, 10510, 14561, 504, 3344, 396, 2139, 24516, 10502, 5592,
    1849, 1850, 367, 18984, 23020, 159, 164, 3625,
  ];

  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    39, 54, 65, 159, 164, 396, 448, 504, 740, 743, 924, 1131, 1342, 1491, 2139,
    2621, 2622, 2941, 3184, 3344, 3625, 4285, 4655, 4724, 5044, 10136, 10502,
    10902, 11787, 14561, 15862, 16453, 16454, 16455, 16456, 16457, 16458, 16459,
    16460, 367, 1850, 1849, 5592, 18985, 22927, 22928, 23020, 23097, 24859,
    3206, 4145, 25012, 18984, 24400, 10510, 24516, 25079,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={(e) => console.log(e)}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/listado-hoja-ruta",
          parametros: ["listadoHojaRuta"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Range Fecha Hoja de Ruta",
              type: "date",
            },
            {
              label: filterNames[1].label,
              variable: "Cliente",
              type: "dropdown",
              parametros: ["descripcion"],
              urlApi: "all-clientes",
              descripcionAddCode: true,
              optionAll: true,
            },
            {
              label: filterNames[2].label,
              variable: "Tipo Despacho",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "tipo-despacho-options",
              optionAll: true,
            },
            {
              label: filterNames[3].label,
              variable: "Proveedor Tractor",
              type: "dropdown",
              parametros: ["descripcion"],
              urlApi: "proveedores-options",
              optionAll: true,
            },
            {
              label: filterNames[4].label,
              variable: "Estado",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "estado-hoja-ruta-options",
              optionAll: true,
            },
          ],
        }}
      />
      <Panel header="Listado de Hoja Ruta">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          <Column field="" header={columnsNames[19].label} sortable filter />
          <Column field="" header={columnsNames[20].label} sortable filter />
          <Column field="" header={columnsNames[21].label} sortable filter />
          <Column field="" header={columnsNames[22].label} sortable filter />
          <Column field="" header={columnsNames[23].label} sortable filter />
          <Column field="" header={columnsNames[24].label} sortable filter />
          <Column field="" header={columnsNames[25].label} sortable filter />
          <Column field="" header={columnsNames[26].label} sortable filter />
          <Column field="" header={columnsNames[27].label} sortable filter />
          <Column field="" header={columnsNames[28].label} sortable filter />
          <Column field="" header={columnsNames[29].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ListadoHojaRuta;

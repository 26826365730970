import React, { useEffect, useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import usePageViews from "../../../hooks/usePageViews";

export function Stock() {
  const SCREEN_CODE = "con0027_stock";
  const [stockData, setStockData] = useState(null);
  const dt = useRef(null);
  const data = stockData?.filas;
  const columns = [];
  const filterLabelId = [
    1241, 1242, 21417, 21473, 11443, 1149, 4397, 449, 10774, 2496, 369, 396,
  ];
  const filterNames = [];
  const filterColumnsId = [
    54, 4791, 1491, 990, 991, 992, 6781, 13358, 3774, 21422, 21418, 10774, 2496,
    396, 6590, 408, 491, 13335, 6590, 18202, 992, 369, 13798, 12103, 21430,
    10979, 4724, 11443, 16932,
    //21412,
    21906, 14456, 19927, 54, 4791, 1491, 990, 991, 992, 10774, 2496, 396, 6590,
    408, 491, 13335, 10021, 31, 6590, 18202, 16809, 9387, 369, 932, 2941, 492,
    13798, 12103, 21430, 10979, 11307, 10758, 4724, 11443, 16932, 1, 14456,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 467, 468, 449, 990, 991, 992, 10774, 2496, 369,
    396, 408, 16808, 16809, 491, 10021, 748, 749, 2, 1182, 16531, 10531, 1241,
    1242, 1149, 4397, 4791, 1491, 6590, 13335, 31, 13798, 12103, 10979, 11307,
    9387, 10758, 4724, 932, 2941, 492, 11443, 21417, 21418, 21473, 992, 6781,
    13358, 3774, 21422, 16932, 21906,
    //21412,
    19927, 14456, 21430, 18202,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  usePageViews();

  return (
    <div>
      <Filter
        onSearch={setStockData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/stock",
          parametros: ["Stock"],
          customFilters: [
            {
              variable: "Stock",
              type: "radioButton",
              options: [filterNames[0].label, filterNames[1].label],
            },
            {
              label: filterNames[2].label,
              variable: "Mostrar razón Social Ultima NF",
              type: "checkbox",
            },
            {
              label: filterNames[3].label,
              variable: "Estado Remito",
              type: "multipleSelection",
              urlApi: "estado-remito-options",
              parametros: ["codPais", "descripcion"],
            },
            {
              label: filterNames[4].label,
              variable: "Estado Vehículo",
              type: "multipleSelection",
              urlApi: "estado-vehiculo-options",
              parametros: ["codPais", "codCuenta", "descripcion"],
            },
            {
              label: filterNames[5].label,
              variable: "Lista Vehículos con Daños",
              type: "dropdown",
              options: [],
              urlApi: "codigo-playa-options",
              parametros: ["codPais"],
            },
            {
              label: filterNames[6].label,
              variable: "Lista Vehículos con Bloqueo",
              type: "dropdown",
              options: [],
              urlApi: "codigo-playa-options",
              parametros: ["codPais"],
            },
            {
              label: filterNames[7].label,
              variable: "Playas",
              type: "multipleSelection",
              urlApi: "playas-options",
              parametros: ["codCuenta", "descripcion"],
            },
            {
              label: filterNames[8].label,
              variable: "Mercado",
              type: "multipleSelection",
              urlApi: "mercados-options",
              parametros: ["codPais", "descripcion"],
            },
            {
              label: filterNames[9].label,
              variable: "Origen",
              type: "multipleSelection",
              urlApi: "origen-options",
              parametros: ["codPais", "descripcion"],
            },
            {
              label: filterNames[10].label,
              variable: "Destinos",
              type: "multipleSelection",
              urlApi: "destinos-options",
              parametros: ["codPais", "descripcion"],
              descripcionAddCode: true,
            },
            //--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--
            //{label: filterNames[11].label, variable: "Marca", type: "multipleSelection", urlApi: "marcas-options",
            // parametros:["codPais", "codCliente", "descripcion"]},
          ],
        }}
      />
      <Panel header="Stock">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          <Column field="" header={columnsNames[19].label} sortable filter />
          <Column field="" header={columnsNames[20].label} sortable filter />
          <Column field="" header={columnsNames[21].label} sortable filter />
          <Column field="" header={columnsNames[22].label} sortable filter />
          <Column field="" header={columnsNames[23].label} sortable filter />
          <Column field="" header={columnsNames[24].label} sortable filter />
          <Column field="" header={columnsNames[25].label} sortable filter />
          <Column field="" header={columnsNames[26].label} sortable filter />
          <Column field="" header={columnsNames[27].label} sortable filter />
          <Column field="" header={columnsNames[28].label} sortable filter />
          <Column field="" header={columnsNames[29].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default Stock;

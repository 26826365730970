import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useDebounce } from "primereact/hooks";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function HistoricoDanos() {
  const SCREEN_CODE = "con0026_historico_danios";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    467, 468, 1357, 11078, 1551, 1552, 901, 14535, 10541, 16243, 17007, 21675,
    10774, 2496, 396, 408, 16808, 491, 10021, 16809,
  ];
  const filterNames = [];
  const filterColumnsId = [
    20216, 1, 1, 1, 1, 1, 1, 1, 1396, 2892, 54, 1491, 10540, 16243, 21675, 7280,
    21836, 16505, 16246, 11314, 7811, 11097, 3344, 20854, 10774, 2496, 396, 408,
    491, 10021, 6590, 16809, 6590, 32, 16544, 1, 16576, 2941, 11070, 14712,
    4870, 4871, 4655, 18096, 18125, 18097, 18099, 18098, 1269, 19126, 14535,
    1355, 16541, 55555, 55555, 55555,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 2, 32, 54, 65, 396, 408, 467, 468, 491, 748, 749, 1182, 1357, 1396, 1491,
    1551, 1552, 1791, 2496, 3344, 20854, 2502, 2892, 2941, 4655, 4870, 4871,
    5044, 5583, 6590, 7811, 10021, 10531, 10540, 10774, 11070, 11078, 11097,
    11314, 14712, 16243, 16246, 16505, 16531, 16544, 16576, 16808, 16809, 18096,
    18097, 18098, 18099, 18125, 10541, 16243, 17007, 14535, 1269, 19126, 14535,
    20216, 20238, 21675, 21836, 7280, 1355, 16541, 653, 1480, 901,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }

  const [listVehiclesWithRepairedDamages, setListVehiclesWithRepairedDamages] =
    useState([]);
  const [blockConsepts, setBlockConsepts] = useState([]);
  const [dateTypesToUse, setDateTypeToUse] = useState([
    filterNames[4].label,
    filterNames[5].label,
  ]);

  const [destinations, setDestinations] = useState([]);
  const [damageDescriptions, setDamageDescriptions] = useState([]);
  const [damagedParts, setDamagedParts] = useState([]);
  const [damagedSeverities, setDamagedSeverities] = useState([]);
  const [damageSection, setDamageSection] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [colors, setColors] = useState([]);
  const [families, setFamilies] = useState([]);
  const [categories, setCategories] = useState([]);

  const [filter, debouncedFilter, setFilter] = useDebounce(
    {
      dateRange: [],
      listVehiclesWithRepairedDamages: null,
      blockConsepts: null,
      dateTypesToUse: "",
      vin: "",
      destinations: [],
      damageDescriptions: [],
      damagedParts: [],
      damagedSeverities: [],
      damageSection: [],
      markets: [],
      origins: [],
      brands: [],
      models: [],
      versions: [],
      colors: [],
      families: [],
      categories: [],
    },
    400
  );

  async function searchBrands(event: any) {
    await loadBrands(event.query);
  }
  async function loadBrands(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/marca",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setBrands(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setBrands(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchModels(event: any) {
    await loadModels(event.query);
  }
  async function loadModels(query = "") {
    try {
      if (filter.brands.length === 1) {
        const selectedBrand = filter.brands[0];
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/modelos",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setModels(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setModels(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchListVehiclesWithRepairedDamages(event: any) {
    await loadListVehiclesWithRepairedDamages(event.query);
  }
  async function loadListVehiclesWithRepairedDamages(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/codigo-playa",
        { params: { codPais: securityFilters.securityValues.country.value } }
      );
      if (status === 200) {
        if (query) {
          setListVehiclesWithRepairedDamages(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setListVehiclesWithRepairedDamages(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchBloackConsepts(event: any) {
    await loadBlockConcepts(event.query);
  }
  async function loadBlockConcepts(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/codigo-playa",
        { params: { codPais: securityFilters.securityValues.country.value } }
      );
      if (status === 200) {
        if (query) {
          setBlockConsepts(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setBlockConsepts(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchDestinations(event: any) {
    await loadDestinations(event.query);
  }
  async function loadDestinations(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/destino",
        { params: { codPais: securityFilters.securityValues.country.value } }
      );
      if (status === 200) {
        const destination = data.map((x: any) => {
          return { ...x, label: `${x.label} (${x.id})` };
        });
        if (query) {
          setDestinations(
            destination.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setDestinations(destination);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchOrigin(event: any) {
    await loadOrigin(event.query);
  }
  async function loadOrigin(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/origen",
        { params: { codPais: securityFilters.securityValues.country.value } }
      );
      if (status === 200) {
        if (query) {
          setOrigins(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setOrigins(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchDamageDescriptions(event: any) {
    await loadDamageDescription(event.query);
  }
  async function loadDamageDescription(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/descripcion-danios",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setDamageDescriptions(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setDamageDescriptions(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchDamagedSeverity(event: any) {
    await loadDamagedSeverity(event.query);
  }
  async function loadDamagedSeverity(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/gravedad-danios",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setDamagedSeverities(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setDamagedSeverities(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchDamagedSections(event: any) {
    await loadDamagedSections(event.query);
  }
  async function loadDamagedSections(query = "") {
    try {
      if (filter.damagedParts.length === 1) {
        const damagedPart = filter.damagedParts[0];
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/cuadrantes",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codParteDanio: damagedPart.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setDamageSection(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setDamageSection(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchMercados(event: any) {
    await loadMercados(event.query);
  }
  async function loadMercados(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/mercados",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setMarkets(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setMarkets(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchDamagedParts(event: any) {
    await loadDamagedParts(event.query);
  }
  async function loadDamagedParts(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/partes-danadas",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setDamagedParts(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setDamagedParts(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchVersions(event: any) {
    await loadVersions(event.query);
  }
  async function loadVersions(query = "") {
    try {
      if (filter.brands.length === 1) {
        const selectedBrand = filter.brands[0];
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/versiones",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setVersions(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setVersions(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchColors(event: any) {
    await loadColors(event.query);
  }
  async function loadColors(query = "") {
    try {
      if (filter.brands.length === 1) {
        const selectedBrand = filter.brands[0];
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/colores",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setColors(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setColors(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchFamilies(event: any) {
    await loadFamilies(event.query);
  }
  async function loadFamilies(query = "") {
    try {
      if (filter.brands.length === 1) {
        const selectedBrand = filter.brands[0];
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/familias",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setFamilies(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setFamilies(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchCategories(event: any) {
    await loadCategories(event.query);
  }
  async function loadCategories(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/categorias",
        {
          params: {
            codPais: securityFilters.securityValues.country.value,
            codCliente: securityFilters.securityValues.client.value,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setCategories(
            data.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setCategories(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "historico-danos/report",
        {
          codPais: securityFilters?.securityValues?.country?.value || null,
          codCliente: securityFilters?.securityValues?.client?.value || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.value || null,
          codSubcuenta:
            securityFilters?.securityValues?.subaccount?.value || null,
          mercado: filter?.markets?.map((x) => x.id) || [],
          destinos: filter?.destinations?.map((x) => x.id) || [],
          origens: filter?.origins?.map((x) => x.id) || [],
          rangeFecha: filter?.dateRange || [],
          vin: filter?.vin || "",
          marcas: filter?.brands?.map((x) => x.id) || [],
          modelos: filter?.models?.map((x) => x.id) || [],
          versiones: filter?.versions?.map((x) => x.id) || [],
          colores: filter?.colors?.map((x) => x.id) || [],
          familias: filter?.families?.map((x) => x.id) || [],
          categorias: filter?.categories?.map((x) => x.id) || [],
          conceptosBloqueadores: filter?.blockConsepts?.id || "",
          listarVehiculosConDanios:
            filter?.listVehiclesWithRepairedDamages?.id || "",
          fechaUsadaHistoricoDanios:
            filter?.dateTypesToUse === "Usa fecha en que se produjo el Daño"
              ? "true"
              : "false" || "",
          descripcionesDanios:
            filter?.damageDescriptions?.map((x) => x.id) || [],
          partesDanadas: filter?.damagedParts?.map((x) => x.id) || [],
          gravedadDeDanios: filter?.damagedSeverities?.map((x) => x.id) || [],
          cuadranteDanios: filter?.damageSection?.map((x) => x.id) || [],
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadBlockConcepts();
    loadListVehiclesWithRepairedDamages();
  }, []);

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[1].id} alt="Fecha" />
              </label>
              <Calendar
                value={filter.dateRange}
                selectionMode="range"
                onChange={(e) => {
                  setFilter({ ...filter, dateRange: e.value });
                }}
                dateFormat="dd/mm/yy"
                hideOnRangeSelection
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[2].id}
                  alt="Listar Vehiculos com Danõs Reparados"
                />
              </label>
              <AutoComplete
                value={filter.listVehiclesWithRepairedDamages}
                suggestions={listVehiclesWithRepairedDamages}
                completeMethod={searchListVehiclesWithRepairedDamages}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    listVehiclesWithRepairedDamages: e.value,
                  });
                }}
                field="descripcionFull"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[3].id}
                  alt="Conceptos Bloqueantes"
                />
              </label>
              <AutoComplete
                value={filter.blockConsepts}
                suggestions={blockConsepts}
                completeMethod={searchBloackConsepts}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    blockConsepts: e.value,
                  });
                }}
                field="descripcionFull"
                dropdown
              />
            </div>
            <div className="sipco-option">
              <label />
              <div className="RadioButtonFilter">
                {dateTypesToUse.map((option, index) => (
                  <div key={index}>
                    <RadioButton
                      value={option}
                      placeholder={"Fecha"}
                      name={"Fecha"}
                      onChange={(e) =>
                        setFilter({ ...filter, dateTypesToUse: e.value })
                      }
                      checked={filter.dateTypesToUse == option}
                    />
                    <label>{option}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[6].id} alt="Ingrese el VIN" />
              </label>
              <InputText
                value={filter.vin}
                onChange={(e) => setFilter({ ...filter, vin: e.target.value })}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[7].id} alt="Destinos" />
              </label>
              <AutoComplete
                value={filter.destinations}
                suggestions={destinations}
                completeMethod={searchDestinations}
                onChange={(e) =>
                  setFilter({ ...filter, destinations: e.value })
                }
                field="label"
                dropdown
                multiple={true}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[8].id}
                  alt="Descripcion Daño"
                />
              </label>
              <AutoComplete
                value={filter.damageDescriptions}
                suggestions={damageDescriptions}
                completeMethod={searchDamageDescriptions}
                onChange={(e) =>
                  setFilter({ ...filter, damageDescriptions: e.value })
                }
                field="label"
                dropdown
                multiple={true}
                disabled={
                  !securityFilters.securityValues.client ||
                  !securityFilters.securityValues.country
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[9].id} alt="Parte Dañada" />
              </label>
              <AutoComplete
                value={filter.damagedParts}
                suggestions={damagedParts}
                completeMethod={searchDamagedParts}
                onChange={(e) =>
                  setFilter({ ...filter, damagedParts: e.value })
                }
                field="label"
                dropdown
                multiple={true}
                disabled={
                  !securityFilters.securityValues.client ||
                  !securityFilters.securityValues.country
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[10].id}
                  alt="Gravedad del Daño"
                />
              </label>
              <AutoComplete
                value={filter.damagedSeverities}
                suggestions={damagedSeverities}
                completeMethod={searchDamagedSeverity}
                onChange={(e) =>
                  setFilter({ ...filter, damagedSeverities: e.value })
                }
                field="label"
                dropdown
                multiple={true}
                disabled={
                  !securityFilters.securityValues.client ||
                  !securityFilters.securityValues.country
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[11].id}
                  alt="Cuadrante (LD)"
                />
              </label>
              <AutoComplete
                value={filter.damageSection}
                suggestions={damageSection}
                completeMethod={searchDamagedSections}
                onChange={(e) =>
                  setFilter({ ...filter, damageSection: e.value })
                }
                field="label"
                dropdown
                multiple={true}
                disabled={
                  !securityFilters.securityValues.client ||
                  !securityFilters.securityValues.country ||
                  filter.damagedParts.length !== 1
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[12].id} alt="Mercado" />
              </label>
              <AutoComplete
                value={filter.markets}
                suggestions={markets}
                completeMethod={searchMercados}
                onChange={(e) => setFilter({ ...filter, markets: e.value })}
                field="label"
                dropdown
                multiple={true}
                disabled={!securityFilters.securityValues.country}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[13].id} alt="Origen" />
              </label>
              <AutoComplete
                value={filter.origins}
                suggestions={origins}
                completeMethod={searchOrigin}
                onChange={(e) => setFilter({ ...filter, origins: e.value })}
                field="label"
                dropdown
                multiple={true}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[14].id} alt="Marca" />
              </label>
              <AutoComplete
                value={filter.brands}
                suggestions={brands}
                completeMethod={searchBrands}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    brands: e.value,
                  });
                }}
                field="label"
                multiple
                dropdown
                disabled={
                  !securityFilters.securityValues.client ||
                  !securityFilters.securityValues.country
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[15].id} alt="Modelo" />
              </label>
              <AutoComplete
                value={filter.models}
                suggestions={models}
                completeMethod={searchModels}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    models: e.value,
                  });
                }}
                field="label"
                dropdown
                multiple
                disabled={filter.brands.length !== 1}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[16].id} alt="Versiones" />
              </label>
              <AutoComplete
                value={filter.versions}
                suggestions={versions}
                completeMethod={searchVersions}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    versions: e.value,
                  });
                }}
                field="label"
                dropdown
                multiple
                disabled={
                  !securityFilters.securityValues.country ||
                  !securityFilters.securityValues.client ||
                  filter.brands.length !== 1
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[17].id} alt="Colores" />
              </label>
              <AutoComplete
                value={filter.colors}
                suggestions={colors}
                completeMethod={searchColors}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    colors: e.value,
                  });
                }}
                field="label"
                dropdown
                multiple
                disabled={
                  !securityFilters.securityValues.country ||
                  !securityFilters.securityValues.client ||
                  filter.brands.length !== 1
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[18].id} alt="Familias" />
              </label>
              <AutoComplete
                value={filter.families}
                suggestions={families}
                completeMethod={searchFamilies}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    families: e.value,
                  });
                }}
                field="label"
                dropdown
                multiple
                disabled={
                  !securityFilters.securityValues.country ||
                  !securityFilters.securityValues.client ||
                  filter.brands.length !== 1
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[19].id} alt="Categorias" />
              </label>
              <AutoComplete
                value={filter.categories}
                suggestions={categories}
                completeMethod={searchCategories}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    categories: e.value,
                  });
                }}
                field="label"
                dropdown
                multiple
                disabled={
                  !securityFilters.securityValues.country ||
                  !securityFilters.securityValues.client ||
                  filter.brands.length !== 1
                }
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel header="Listado Operaciones Realizadas">
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          footer={
            <ExportButtons
              dt={dt}
              data={data}
              columns={columnsNames}
              screenName={"StockTaller"}
            />
          }
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="row"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />
          <Column field="30" header={columnsNames[30].label} sortable filter />
          <Column field="31" header={columnsNames[31].label} sortable filter />
          <Column field="32" header={columnsNames[32].label} sortable filter />
          <Column field="33" header={columnsNames[33].label} sortable filter />
          <Column field="34" header={columnsNames[34].label} sortable filter />
          <Column field="35" header={columnsNames[35].label} sortable filter />
          <Column field="36" header={columnsNames[36].label} sortable filter />
          <Column field="37" header={columnsNames[37].label} sortable filter />
          <Column field="38" header={columnsNames[38].label} sortable filter />
          <Column field="39" header={columnsNames[39].label} sortable filter />
          <Column field="40" header={columnsNames[40].label} sortable filter />
          <Column field="41" header={columnsNames[41].label} sortable filter />
          <Column field="42" header={columnsNames[42].label} sortable filter />
          <Column field="43" header={columnsNames[43].label} sortable filter />
          <Column field="44" header={columnsNames[44].label} sortable filter />
          <Column field="45" header={columnsNames[45].label} sortable filter />
          <Column field="46" header={columnsNames[46].label} sortable filter />
          <Column field="47" header={columnsNames[47].label} sortable filter />
          <Column field="48" header={columnsNames[48].label} sortable filter />
          <Column field="49" header={columnsNames[49].label} sortable filter />
          <Column field="50" header={columnsNames[50].label} sortable filter />
          <Column field="51" header={columnsNames[51].label} sortable filter />
          <Column field="52" header={columnsNames[52].label} sortable filter />
          <Column field="53" header={columnsNames[53].label} sortable filter />
          <Column field="54" header={columnsNames[54].label} sortable filter />
          <Column field="55" header={columnsNames[55].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default HistoricoDanos;

import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { createContext, useContext, useState } from "react";
import useAuth, { AuthContext } from "@shared/AuthContext";

import { DropdownValueDTO } from "@shared/components/filter/components/security-filter-interfaces";

export interface FilterContextProps {
  values: FilterData;
  setValues: React.Dispatch<React.SetStateAction<FilterData>>;
}

export interface FilterData {
  securityValues: FilterSecurityOptionsValues;
  filterValues: FilterValues;
  customFilters?: {
    [key: string]: any;
  };
}

export interface FilterSecurityOptionsValues {
  country: DropdownValueDTO<number>;
  client: DropdownValueDTO<string>;
  account: DropdownValueDTO<number>;
  subaccount: DropdownValueDTO<number>;
}

export interface FilterValues {
  customFilters: {
    [key: string]: any;
  };
}

const FilterContext = createContext<FilterContextProps>(null);

const ProtectedRoutes = () => {
  const { user } = useAuth();
  const location = useLocation();

  const emptyFilterValues: FilterData = {
    securityValues: {
      country: null,
      client: null,
      account: null,
      subaccount: null,
    },
    filterValues: {
      customFilters: {},
    },
  };

  const [values, setValues] = useState<FilterData>(emptyFilterValues);

  if (user) {
    return (
      <FilterContext.Provider value={{ values, setValues }}>
        <Outlet />
      </FilterContext.Provider>
    );
  }
  return <Navigate to="auth/login" replace state={{ from: location }} />;
};

export default ProtectedRoutes;

export function useFilterContext() {
  return useContext(FilterContext);
}

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import React from "react";
import {LanguageProvider} from "@shared/components/language-provider";
import {convertDateObjects} from "@shared/components/utils/convertDateObjects";
const DamageHistory: React.FC<{ vinData: any }> = ({ vinData }) => {

    const filterLabelId =[]
    const filterNames = [];
    const filterColumnsId = [0,16541,10540,2490,21675,7280,21836,16575,7811,11097,3344,6197,20854,0,3741,11314,14288,16576,16577,16544,16456,18096,18125,18097,18099,18098]
    const columnsNames = [];
    const data =  convertDateObjects(vinData?.historicoDanios);
    for (let i = 0; i < filterLabelId.length; i++) {
        filterNames.push({
            id: filterLabelId[i],
            label: LanguageProvider({ id: filterLabelId[i], alt: "Error Filter Labels" })
        });
    }
    for (let i = 0; i < filterColumnsId.length; i++) {
        columnsNames.push({
            id: filterColumnsId[i],
            label: LanguageProvider({ id: filterColumnsId[i] , alt: "Error Columns Labels"})
        });
    }

    return (
        <div className="DamageHistory">
            <Panel >
                <DataTable size={"small"} showGridlines stripedRows      value={vinData?.historicoDanios}
                               paginator scrollHeight="flex" filterDisplay="row" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}" style={{maxWidth:"100%"}} >
                    <Column field="fechaCarga" sortable filter header="Fecha de carga"></Column>
                    <Column field="tipoDeDanio.descripcionFull" sortable filter header="Codigo de dano"></Column>
                    <Column field="partesDanios.descripcionFull" sortable filter header="Parte"></Column>
                    <Column field="cuadrante" sortable filter header="Cuadrante"></Column>
                    <Column field="unidadMedida" sortable filter header="Unidade de Medida"></Column>
                    <Column field="valorUnidadDeMedida" sortable filter header="Valor Unidade de Medida"></Column>
                    <Column field="gravedadDeDanio.descripcionFull" sortable filter header="Codigo Gravidade"></Column>
                    <Column field="imputacion.id" sortable filter header="Imputacion"></Column>
                    <Column field="responsable.descripcion" sortable filter header="Responsável"></Column>
                    <Column field="estadoDanio.descripcion" sortable filter header="Estado"></Column>
                    <Column field="fechaBaja" sortable filter header="Fecha Baja"></Column>
                    <Column field="motivoBajaDanio" sortable filter header="Motivo Baja Dano"></Column>
                    <Column field="usuario.id" sortable filter header="Usuario"></Column>
                    <Column field="checkpoint.id" sortable filter header="CheckPoint"></Column>
                    <Column field="transaccion.descripcionFull" sortable filter header="Transação"></Column>
                    <Column field="fechaReparado" sortable filter header="Fecha Reparo"></Column>
                    <Column field="fechaDanio" sortable filter header="Fecha de Dano"></Column>
                    <Column field="observacion" sortable filter header="Observação do Dano"></Column>
                    <Column field="usuarioUltimaModificacion.id" sortable filter header="Modificação"></Column>
                    <Column field="numeroDossier" sortable filter header="Número Dossie"></Column>
                    <Column field="comentarioDossier" sortable filter header="Comentario Dossie"></Column>
                    <Column field="" sortable filter header="Fecha SAGAI"></Column>
                    <Column field="numeroCheckList" sortable filter header="Número CheckList"></Column>

                </DataTable>
            </Panel>
        </div>
    );
};
export default DamageHistory